import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    IconDefinition,
    faAngleDown,
    faClock,
    faCoins,
    faFileCircleQuestion,
    faHashtag,
    faIdCardClip,
    faInfo,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { updateStatus } from "../../firebase/firestore";
import { AirTicketBooking, Application } from "../../utils/interfaces";
import { formatDate } from "../../utils/utils";
import { useAppDispatch } from "../../hooks/reduxHooks";
// import ApplicationsItemGeneral from "./ApplicationsItemGeneral";
// import ApplicationsItemCost from "./ApplicationsItemCost";
// import ApplicationsItemApplicants from "./ApplicationsItemApplicants";
// import ApplicationsItemProfile from "./ApplicationsItemProfile";
// import ApplicationsItemQuestionnaire from "./ApplicationsItemQuestionnaire";
import { StatusTypes } from "../../utils/enums";
import { icon } from "@fortawesome/fontawesome-svg-core";
import TicketGeneralInfoBlock from "./GeneralInfoBlock";
import TicketApplicants from "./ApplicantsBlock";
import StatusChangeButton from "../StatusChangeButton";
import { updateTicketStatus } from "../../redux/slices/airTicketsSlice";

export interface AirTicketItemProps {
    ticket: AirTicketBooking;
}

interface Data {
    icon: IconDefinition;
}

const data: Data[] = [
    {
        icon: faInfo,
    },
    {
        icon: faUsers,
    },
];

const AirTicketItem: React.FC<AirTicketItemProps> = ({ ticket }) => {
    const dispatch = useAppDispatch();
    const [activeIndex, setActiveIndex] = useState<number>(0);


    return (
        <div className="tickets-item">
            <div className="tickets-item__btn-list">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`tickets-item__btn-item ${activeIndex === index && "tickets-item__btn-item-active"
                            }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <FontAwesomeIcon icon={item.icon} />
                    </div>
                ))}
            </div>
            <div className="tickets-item__header">
                <div className="tickets-item__header-item">
                    <FontAwesomeIcon icon={faHashtag}></FontAwesomeIcon>
                    <h3 className="tickets-item__header-item-heading">{ticket.orderID}</h3>
                </div>
                <div className="tickets-item__header-item">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <h6 className="tickets-item__header-item-text">{ticket.createdAt}</h6>
                </div>
            </div>
            <div className="tickets-item__body">
                {
                    activeIndex === 0 ? <TicketGeneralInfoBlock ticket={ticket}></TicketGeneralInfoBlock>
                        : <TicketApplicants applicants={ticket.clients}></TicketApplicants>
                }
            </div>
            {/* { application.statusType === StatusTypes.NEW && <div className="tickets-item__new">Новое</div> } */}
        </div>
    );
};

export default AirTicketItem;
