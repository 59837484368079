import { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import SectionHeader from '../../components/SectionHeader';
import { DocumentSnapshot } from 'firebase/firestore';
import {getAllImmigrationVisas, getTotalAmount} from '../../firebase/firestore';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { addImmigrationVisas, changeImmigrationVisasActiveStatus, updateLastDoc, updatePageNumber } from '../../redux/slices/immigrationVisaSlice';
import { RootState } from '../../redux/store';
import { GeneralStatuses, IMMIGRATION_VISAS_COLLECTION } from '../../utils/consts';
import { StatusTypes } from '../../utils/enums';
import { ImmigrationVisa } from '../../utils/interfaces';
import ImmigrationVisasList from './components/ImmigrationVisaList';
import Pagination from '../../components/Pagination';
import usePagination from '../../hooks/usePagination';

const ImmigrationVisasPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState(0)
  const immigrationVisas: ImmigrationVisa[] = useAppSelector((state: RootState) => state.immigrationVisas.immigrationVisasList);
  const numberOfNewApplications: number = useAppSelector((state: RootState) => state.immigrationVisas.immigrationVisasList.filter((item) => item.status === StatusTypes.NEW).length);
  const activeStatus: string = useAppSelector((state: RootState) => state.immigrationVisas.activeStatus);
  const lastDocId = useAppSelector((state: RootState) => state.immigrationVisas.lastDocId)
  const currentPage = useAppSelector((state:RootState)=>state.immigrationVisas.currentPage)
  const fetchImmigrationVisas = async () => {
    setIsLoading(true);
    console.log('last document Id ' + lastDocId);
    const amount = await getTotalAmount(IMMIGRATION_VISAS_COLLECTION,activeStatus)
    setTotalAmount(amount)
    console.log(amount)
    const {immigrationVisas,newLastDocId} = await getAllImmigrationVisas(activeStatus, lastDocId);
    dispatch(addImmigrationVisas(immigrationVisas));
    dispatch(updateLastDoc(newLastDocId))
    setIsLoading(false);
  }

  useEffect(() => {
    fetchImmigrationVisas();
  }, [activeStatus,currentPage]);

  const setActiveStatus = (status: string) => {
    dispatch(updatePageNumber(1))
    dispatch(updateLastDoc(null))
    dispatch(changeImmigrationVisasActiveStatus(status));
  };
 

  return (
    <div className="content">
      <div className="iv">
        <h2 className="section-title">Заявки на Иммиграционную Визу</h2>
        <SectionHeader
          searchIsNumeric={true}
          searchPlaceholder="Поиск по номеру заявки..."
          activeStatus={activeStatus}
          numberOfNewItems={activeStatus === "new" ? totalAmount : 0 }
          statuses={GeneralStatuses}
          setActiveStatus={setActiveStatus}
          hasSearch={false}
        />
        <div className="iv-wrapper">
          {isLoading ? (
            <Loader />
          ) : (
            <ImmigrationVisasList immigrationVisas={immigrationVisas.filter(item => item.status === activeStatus)} />
          )}
        </div>
        <Pagination
          onChangePage={(item) => dispatch(updatePageNumber(item))}
          currentPage={currentPage}
          itemsPerPage={5}
          collectionName={IMMIGRATION_VISAS_COLLECTION}
          currentStatus={activeStatus}
          totalItems={totalAmount}
        ></Pagination>

      </div>
    </div>
  );
}

export default ImmigrationVisasPage;