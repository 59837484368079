import {ApplicantQuestionnaireAnswer, ImmigrationVisa} from "../../../../utils/interfaces";
import ImmigrationVisasInfoBlock from "./InfoBlock";
import StatusChangeButton from "../../../../components/StatusChangeButton";
import {GeneralStatuses, IMMIGRATION_VISAS_COLLECTION} from "../../../../utils/consts";
import {updateImmigrationVisaStatus} from "../../../../redux/slices/immigrationVisaSlice";
import {useAppSelector} from "../../../../hooks/reduxHooks";
import {RootState} from "../../../../redux/store";

interface ImmigrationVisasQuestionnaireSectionProps {
  immigrationVisa:ImmigrationVisa;
  questionnaires: ApplicantQuestionnaireAnswer[];

}

const ImmigrationVisasQuestionnaireSection: React.FC<ImmigrationVisasQuestionnaireSectionProps> = ({ questionnaires,immigrationVisa }) => {
  const activeStatus: string = useAppSelector((state: RootState) => state.immigrationVisas.activeStatus);

  return (
    <div className="iv-item__grid">{
      questionnaires.map(item => (
        <ImmigrationVisasInfoBlock title={item.question} value={item.answer} />
      ))
    }
      <StatusChangeButton
          id={immigrationVisa.id}
          collection={IMMIGRATION_VISAS_COLLECTION}
          rightOffset={36}
          topOffset={0}
          bottomOffset={0}
          statuses={GeneralStatuses}
          activeStatus={activeStatus}
          dispatchMethod={updateImmigrationVisaStatus}
      />
    </div>
  );
}

export default ImmigrationVisasQuestionnaireSection;