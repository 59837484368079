import { SpecialOffer } from "../utils/interfaces";
import NotFound from "./NotFound";
import SpecialOfferItem from "./SpecialOfferItem";

interface SpecialOffersListProps {
  specialOffsers: SpecialOffer[];
}

const SpecialOffersList: React.FC<SpecialOffersListProps> = ({
  specialOffsers,
}) => {
  
  return (
    specialOffsers.length > 0 ? <div className="offers-grid">
      {specialOffsers.map((item,index) => (
        <SpecialOfferItem key={index} specialOffer={item}></SpecialOfferItem>
        // <EmiratesWorkVisaApplicationItem key={item.id} application={item}></EmiratesWorkVisaApplicationItem>
      ))} 
    </div> : <NotFound/>
  );
};

export default SpecialOffersList;