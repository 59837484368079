import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { RootState } from '../redux/store';
import {Insurance, Status} from '../utils/interfaces';
import {getTotalAmount2, paginationFunction} from '../firebase/firestore';
import SectionHeader from '../components/SectionHeader';
import { INSURANCE_COLLECTION} from '../utils/consts';
import Loader from '../components/Loader';
import InsuranceList from '../components/InsuranceList';
import {
changeSearchText,
    changeInsuranceStatus,
    addInsurances,
    updateLastDoc,
    updatePageNumber
} from "../redux/slices/insuranceSlice";
import Pagination from "../components/Pagination";

export const GreenCardsStatuses: Status[] = [
    {
        eng: 'new',
        rus: 'Новые'
    },
    {
        eng: 'process',
        rus: 'В процессе'
    },
    {
        eng: 'won',
        rus: 'Одобренные'
    },
    {
        eng: 'lost',
        rus: 'Не одобренные'
    }
];

const InsurancePage: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const insuranceList: Insurance[] = useAppSelector((state: RootState) => state.insurance.insuranceList)
    const activeStatus: string = useAppSelector((state: RootState) => state.insurance.activeStatus)
    const searchText: string = useAppSelector((state: RootState) => state.insurance.searchText)
    const currentPage = useAppSelector((state:RootState)=>state.insurance.currentPage)
    const lastDocId = useAppSelector((state:RootState)=>state.insurance.lastDocId)

    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        const fetach = async () => {
            setIsLoading(true);
            const amount = await getTotalAmount2(INSURANCE_COLLECTION,activeStatus)
            setTotalAmount(amount)
            const {items ,newLastDocId } = await paginationFunction<Insurance>(INSURANCE_COLLECTION,lastDocId,activeStatus)
            dispatch(updateLastDoc(newLastDocId))
            dispatch(addInsurances(items));
            setIsLoading(false);
        };
        fetach();
    }, [activeStatus,currentPage]);

    const setActiveStatus = (status: string) => {
        dispatch(updatePageNumber(1))
        dispatch(updateLastDoc(null))
        dispatch(changeInsuranceStatus(status));
    };


    const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeSearchText(e.target.value));
    };

    return (
        <div className="content">
            <div className="tickets">
                <h2 className="section-title">Страховки</h2>
                <SectionHeader
                    searchIsNumeric={true}
                    searchPlaceholder="Поиск по номеру брони..."
                    activeStatus={activeStatus}
                    numberOfNewItems={activeStatus === 'new' ? totalAmount : 0}
                    statuses={GreenCardsStatuses}
                    setActiveStatus={setActiveStatus}
                    handleChangeSearchText={handleChangeSearchText}
                />
                <div className="tickets-wrapper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <InsuranceList insuranceList={insuranceList} ></InsuranceList>
                        // <SpecialOffersList specialOffsers={}></SpecialOffersList>
                        // <EmiratesWorkVisaList applications={specialOffersList.filter((item) => item.status === activeStatus && item.id.toString().includes(searchText))}></EmiratesWorkVisaList>
                    )}
                </div>
                <Pagination
                    onChangePage={(item) => dispatch(updatePageNumber(item))}
                    currentPage={currentPage}
                    itemsPerPage={5}
                    collectionName={INSURANCE_COLLECTION}
                    currentStatus={activeStatus}
                    totalItems={totalAmount}
                ></Pagination>
            </div>
        </div>
    )
}

export default InsurancePage;