
import { RootState } from "../../../redux/store";
import { useAppSelector } from "../../../hooks/reduxHooks";
import {  OnlineConsultation } from "../../../utils/interfaces";
import VisaInfoBlock from "../InfoBlock";
import StatusChangeButton from '../../StatusChangeButton'
import { ONLINE_CONSULTATIONS_COLLECTION } from '../../../utils/consts'
import { updateConsultationState } from "../../../redux/slices/onlineConsultationSlice";
import { Status } from "../../../utils/interfaces";


export const EmiratesWorkStatuses: Status[] = [
    {
        eng: 'new',
        rus: 'Новые'
    },
    {
        eng: 'inProcess',
        rus: 'В процессе'
    },
    {
        eng: 'done',
        rus: 'Проведенные'
    },
];

interface EWorkingVisaProps {
    consultation: OnlineConsultation
}

const OnlineConsultationGeneralInfo: React.FC<EWorkingVisaProps> = ({ consultation }) => {
    const activeStatus: string = useAppSelector(
        (state: RootState) => state.greenCardApplications.activeStatus,
    );

    return (
        <div className="consultations-item__general">
            <h4 className="consultations-item__general__title">Общая информация</h4>
            <div className="consultations-item__general__infoContainer">
                <VisaInfoBlock title="Контактный номер" data={consultation.phoneNumber || 'пусто'}></VisaInfoBlock>
                <VisaInfoBlock title="Имя заказчика" data={consultation.user?.username || 'пусто'}></VisaInfoBlock>
                {/* <VisaInfoBlock title="Время" data={consultation.time || 'пусто'}></VisaInfoBlock> */}
                <VisaInfoBlock title="Тема консультации" data={consultation.question || 'пусто'}></VisaInfoBlock>
                <div className="consultations-status_btn">
                    <StatusChangeButton
                        id={consultation.id}
                        isStatusType={true}
                        collection={ONLINE_CONSULTATIONS_COLLECTION}
                        activeStatus={''}
                        statuses={EmiratesWorkStatuses}
                        bottomOffset={8}
                        topOffset={3}
                        dispatchMethod={updateConsultationState}
                    />
                </div>

            </div>

        </div>
    );
}
export default OnlineConsultationGeneralInfo;