import { createSlice } from '@reduxjs/toolkit';
import { OnlineConsultation } from '../../utils/interfaces';
import { StatusTypes,ConsultationTypes } from '../../utils/enums';

interface OnlineConsultationsState {
    consultations: OnlineConsultation[];
    searchText: string;
    activeStatus: string;
    activeOption:ConsultationTypes;
    currentPage:number;
    lastDocId:string|null
}

const initialState: OnlineConsultationsState = {
    consultations: [],
    searchText: '',
    activeStatus: StatusTypes.NEW,
    activeOption: ConsultationTypes.OFFLINE,
    currentPage:1,
    lastDocId:null
};

export const onlineConsultationsSlice = createSlice({
    name: 'onlineConsultaions',
    initialState,
    reducers: {
        updateConsultationState(state, action) {
            const { id, status } = action.payload;
            const index = state.consultations.findIndex(item => item.id === id);
            if (index !== -1) {
                state.consultations[index].statusType = status;
            }
        },
        addOnlineConsultations(state, action) {
            state.consultations = action.payload
        },
        changeOnlineConsultationStatus(state, action) {
            state.activeStatus = action.payload
        },
        changeConsultationSearchText(state, action) {
            state.searchText = action.payload
        },
        updateLastDoc(state,action){
            state.lastDocId = action.payload
        },
        updatePageNumber(state,action){
            state.currentPage = action.payload
        },
    },
});

export const { updateConsultationState,
    addOnlineConsultations,
    changeConsultationSearchText,
    changeOnlineConsultationStatus,
    updatePageNumber,
    updateLastDoc
} = onlineConsultationsSlice.actions;
export default onlineConsultationsSlice.reducer;
