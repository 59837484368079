import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { RootState } from '../redux/store';
import {EmiratesWorkVisaApplication} from '../utils/interfaces';
import { getTotalAmount2, paginationFunction} from '../firebase/firestore';
import { addEmiratesWorkVisas, changeWorkVisaApplicationStatus, changeApplicationsSearchText} from '../redux/slices/emiratesWorkVisaSlice';
import SectionHeader from '../components/SectionHeader';
import {EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION, GeneralStatuses} from '../utils/consts';
import Loader from '../components/Loader';
import EmiratesWorkVisaList from '../components/EmiratesWorkVisaList';
import {
    updateLastDoc,
    updatePageNumber
} from "../redux/slices/emiratesWorkVisaSlice";
import Pagination from "../components/Pagination";


const EmiratesWorkVisaPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const emiratesImmigrationVisas: EmiratesWorkVisaApplication[] = useAppSelector((state: RootState) => state.emiratesWorkVisas.applicationList)
    const activeStatus:string = useAppSelector((state:RootState)=>state.emiratesWorkVisas.activeStatus)
    const searchText:string = useAppSelector((state:RootState)=>state.emiratesWorkVisas.searchText)
    const currentPage = useAppSelector((state:RootState)=>state.emiratesWorkVisas.currentPage)
    const lastDocId = useAppSelector((state:RootState)=>state.emiratesWorkVisas.lastDocId)

    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        const fetchApplications = async () => {
            setIsLoading(true);
            const amount = await getTotalAmount2(EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION,activeStatus)
            setTotalAmount(amount)
            const {items ,newLastDocId } = await paginationFunction<EmiratesWorkVisaApplication>(EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION,lastDocId,activeStatus)
            dispatch(updateLastDoc(newLastDocId))
            dispatch(addEmiratesWorkVisas(items));
            setIsLoading(false);
        };
        fetchApplications();
    }, [activeStatus,currentPage]);

    const setActiveStatus = (status: string) => {
        dispatch(updatePageNumber(1))
        dispatch(updateLastDoc(null))
        dispatch(changeWorkVisaApplicationStatus(status));
    };

    const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeApplicationsSearchText(e.target.value));
    };
    console.log(emiratesImmigrationVisas);
    
    return (
        <div className="content">
            <div className="workvisas">
                <h2 className="workvisas__title">Заявки на рабочую визу в ОАЭ</h2>
                <SectionHeader
                    searchIsNumeric={true}
                    searchPlaceholder="Поиск по номеру брони..."
                    activeStatus={activeStatus}
                    numberOfNewItems={activeStatus === 'new' ?totalAmount :0}
                    statuses={GeneralStatuses}
                    setActiveStatus={setActiveStatus}
                    handleChangeSearchText={handleChangeSearchText}
                />  
                <div className="tickets-wrapper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <EmiratesWorkVisaList applications={emiratesImmigrationVisas.filter((item) => item.statusType === activeStatus && item.id.toString().includes(searchText))}></EmiratesWorkVisaList>
                    )}
                </div>
                <Pagination
                    onChangePage={(item) => dispatch(updatePageNumber(item))}
                    currentPage={currentPage}
                    itemsPerPage={5}
                    collectionName={EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION}
                    currentStatus={activeStatus}
                    totalItems={totalAmount}
                ></Pagination>
            </div>
        </div>
    )
}

export default EmiratesWorkVisaPage;