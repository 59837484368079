import React, { useState } from 'react'
import { Insurance, SpecialOffer } from '../../utils/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faUsers, faClock, IconDefinition, faFileCircleQuestion, faTag } from '@fortawesome/free-solid-svg-icons'
import { StatusTypes } from '../../utils/enums'

import { Status } from '../../utils/interfaces'
import InsuranceOfferGeneralInfo from './GeneralInfoBlock'
import InsuranceOfferApplicants from './ApplicantsBlock'

interface InsuranceItemProps {
    insurance: Insurance
}

interface Data {
    icon: IconDefinition;
}

const data: Data[] = [
    {
        icon: faInfo,
    },
    {
        icon: faUsers,
    },
];
const InsuranceItem: React.FC<InsuranceItemProps> = (
    { insurance }
) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    return (
        <div className="insurance-item">
            <div className="insurance-item__btn-list">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`insurance-item__btn-item ${activeIndex === index && "insurance-item__btn-item-active"
                            }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <FontAwesomeIcon icon={item.icon} />
                    </div>
                ))}
            </div>
            <div className="insurance-item__header">
                <div className="insurance-item__header-item">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <h6 className="insurance-item__header-item-text">{insurance.createdAt}</h6>
                </div>
                <div className="insurance-item__header-item">
                    <FontAwesomeIcon icon={faTag}></FontAwesomeIcon>
                    <h6 className="insurance-item__header-item-text">{insurance.orderID}</h6>
                </div>
            </div>
            <div className="insurance-item__body">
                {
                    activeIndex === 0 ?
                        <InsuranceOfferGeneralInfo insurance={insurance}></InsuranceOfferGeneralInfo>
                        : <InsuranceOfferApplicants applicants={insurance.insurers}></InsuranceOfferApplicants>
                }
            </div>

            {insurance.statusType === StatusTypes.NEW && <div className="insurance-item__new">Новое</div>}
        </div>
    )
}

export default InsuranceItem