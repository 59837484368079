import React, { useState } from 'react'
import { EmiratesWorkVisaApplication } from '../../utils/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag, faInfo, faUsers, faClock, IconDefinition, faFileCircleQuestion, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { formatDate } from '../../utils/utils'
import EWorkingVisaGeneralInfo from './GeneralInfoBlock'
import EWApplicantBlock from './ApplicantBlock'
import { StatusTypes } from '../../utils/enums'
import EWorkVisaQUestionnaire from './QuestionnaireBlock'
import { ApplicationsStatuses, EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION } from '../../utils/consts'
import { Status } from '../../utils/interfaces'
import { TOURIST_VISAS_COLLECTION } from '../../utils/consts'
import { updateStatus } from '../../firebase/firestore'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { updateApplicationsStatus } from '../../redux/slices/applicationsSlice'
import { updateEmirateWorkVisaStatusType, updateEmiratesApplicationStatus } from '../../redux/slices/emiratesWorkVisaSlice'
interface EmiratesWorkVisaApplicationProps {
    application: EmiratesWorkVisaApplication
}

interface Data {
    icon: IconDefinition;
}

const data: Data[] = [
    {
        icon: faInfo,
    },
    {
        icon: faUsers,
    },
    {
        icon: faFileCircleQuestion
    }
];
const EmiratesWorkVisaApplicationItem: React.FC<EmiratesWorkVisaApplicationProps> = (
    { application }
) => {
    const dispatch = useAppDispatch()
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [listIsOpen, setListIsOpen] = useState<boolean>(false);
    const [statusNumber, setStatusNumber] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const statusIsNumber: boolean = typeof statusNumber === "number";
    const buttonIsDisabled: boolean = !statusIsNumber ? !statusIsNumber : application.status === statusNumber! + 1;
    const changeStatus = async (status: number) => {
        setIsLoading(true);
        await updateStatus(application.id, EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION, status, false);
        dispatch(updateEmiratesApplicationStatus({ id: application.id, status }));
        setIsLoading(false);
        setListIsOpen(false);
    };
    return (
        <div className="applications-item">
            <div className="applications-item__btn-list">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`applications-item__btn-item ${activeIndex === index && "applications-item__btn-item-active"
                            }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <FontAwesomeIcon icon={item.icon} />
                    </div>
                ))}
            </div>
            <div className="applications-item__date">
                <FontAwesomeIcon icon={faClock} />
                <h6>{formatDate(application.createdAt * 1000)}</h6>
            </div>

            <div className="applications-item__btn" onClick={() => setListIsOpen(!listIsOpen)}>
                <div className="applications-item__btn-part">
                    <h3>Статус заявки #{application.status}</h3>
                    <h6>{ApplicationsStatuses[application.status - 1]}</h6>
                </div>
                <div className="applications-item__btn-part">
                    <FontAwesomeIcon icon={faAngleDown} />
                </div>
            </div>

            <div className="applications-item__line"></div>
            {listIsOpen ? <div className="applications-item__statuses">
                {
                    ApplicationsStatuses.map((status, index) => (
                        <>
                            <div
                                className={`applications-item__statuses-item ${statusNumber === index && "applications-item__statuses-item-active"}`}
                                onClick={() => setStatusNumber(index)}>
                                {status}
                            </div>
                            {(statusNumber !== index && statusNumber !== index + 1) && index !== ApplicationsStatuses.length - 1 && <div className="applications-item__statuses-line"></div>}
                        </>
                    ))
                }
                <div className="applications-item__statuses-btn-wrapper">
                    {isLoading && <div className="applications-item__statuses-btn-loader"></div>}
                    <button
                        disabled={buttonIsDisabled}
                        className="applications-item__statuses-btn"
                        onClick={() => changeStatus(statusNumber! + 1)}>
                        Выбрать
                    </button>
                </div>
            </div>

                : (
                    activeIndex === 0 ? (
                        <EWorkingVisaGeneralInfo application={application} />
                    ) : activeIndex === 1 ? (
                        <EWApplicantBlock application={application} cvLink={application.cvLink} applicant={application.user} />
                    ) : activeIndex === 2 ? (
                        <EWorkVisaQUestionnaire application={application} questionnaires={application.questionnaires} />
                    ) : activeIndex === 3 ? (
                        <EWApplicantBlock application={application} applicant={application.user!} />
                    ) : (
                        <EWorkVisaQUestionnaire
                            application={application}
                            questionnaires={application.questionnaires}
                        />
                    )
                )}

            {application.statusType === StatusTypes.NEW && <div className="applications-item__new">Новое</div>}
        </div>
    )
}

export default EmiratesWorkVisaApplicationItem