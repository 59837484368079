import React from "react";


interface TicketInfoBlockProps{
    title: string;
    data?:string|number;
    children?: React.ReactElement;
}

const TicketInfoBlock:React.FC<TicketInfoBlockProps> = (
    {title,data,children}
) =>{
    
    return (
        <div className="tickets-item__body__block">
          <span className="tickets-item__body__block-key">{title}</span>
          {data && <h6 className="tickets-item__body__block-value">{data}</h6>}
          <>{children}</>
        </div>
      );
}

export default TicketInfoBlock

