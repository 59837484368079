import {
  faComments,
  faMap,
  faUsers,
  faAddressCard,
  faAngleLeft,
  faArrowRightFromBracket,
  faAngleRight,
  faEarthAmericas,
  faBriefcase,
  faList,
  faPlane,
  faTag,
  faBriefcaseClock,
  faSave,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {
  TOURIST_VISAS_ROUTE,
  CHAT_ROUTE,
  FEEDBACK_ROUTE,
  GREEN_CARDS_ROUTE,
  USERS_ROUTE,
  IMMIGRATION_VISAS_ROUTE,
  AIR_TICKET_BOOKINGS_ROUTE,
  EMIRATES_WORK_VISA_APPLICATIONS,
  SPECIAL_OFFERS,
  OFFLINE_CONSULTATIONS_ROUTE,
  INSURANCE_ROUTE,
  ONLINE_CONSULTATIONS_ROUTE,
  TO_CONTACT_FOR_POLAND_ROUTE
} from "../../utils/consts";
import { firebaseAuthSignOut } from "../../firebase/auth";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { removeUser } from "../../redux/slices/userSlice";
import { toggleSidebar } from "../../redux/slices/settingsSlice";
import { RootState } from "../../redux/store";
import SidebarLink from "./SidebarLink";
import { faMeetup } from "@fortawesome/free-brands-svg-icons";

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const sidebarIsFull = useAppSelector((state: RootState) => state.settings.sidebarIsFull);

  const logOut = async () => {
    await firebaseAuthSignOut();
    dispatch(removeUser());
  };
  return (
    <nav className="sidebar">
      <div className="sidebar-part">
        <ul className="sidebar-links__list">
          <SidebarLink name="Скрыть меню" icon={sidebarIsFull ? faAngleLeft : faAngleRight} func={() => dispatch(toggleSidebar(null))} />
          <SidebarLink name="Туристические визы" link={TOURIST_VISAS_ROUTE} icon={faEarthAmericas} />
          {/* <SidebarLink name="Рабочие визы" link={TOURIST_VISAS_ROUTE} icon={faBriefcase} /> */}
          <SidebarLink name="Рабочие визы в ОАЭ" link={EMIRATES_WORK_VISA_APPLICATIONS} icon={faBriefcase}></SidebarLink>
          <SidebarLink name="Специальные предложения" link={SPECIAL_OFFERS} icon={faTag}></SidebarLink>
          <SidebarLink name="Консультации" link={OFFLINE_CONSULTATIONS_ROUTE} icon={faMeetup}></SidebarLink>
          <SidebarLink name="Онлайн Консультации" link={ONLINE_CONSULTATIONS_ROUTE} icon={faMeetup}></SidebarLink>
          <SidebarLink name="Иммиграционные визы" link={IMMIGRATION_VISAS_ROUTE} icon={faList} />
          <SidebarLink name="Авиабилеты" link={AIR_TICKET_BOOKINGS_ROUTE} icon={faPlane}></SidebarLink>
          <SidebarLink name="Грин-карты" link={GREEN_CARDS_ROUTE} icon={faAddressCard} />
          <SidebarLink name="Чат" link={CHAT_ROUTE} icon={faComments} />
          <SidebarLink name="Обратная связь" link={FEEDBACK_ROUTE} icon={faMap} />
          <SidebarLink name="Страховки" link={INSURANCE_ROUTE} icon={faSave} />
          <SidebarLink name="Польша" link={TO_CONTACT_FOR_POLAND_ROUTE} icon={faUser}></SidebarLink>
          <SidebarLink name="Пользователи" link={USERS_ROUTE} icon={faUsers} />
        </ul>
      </div>
      <div className="sidebar-part">
        <SidebarLink name="Выйти" icon={faArrowRightFromBracket} func={logOut} />
      </div>
    </nav>
  );
};

export default Sidebar;
