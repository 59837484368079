import { createSlice } from '@reduxjs/toolkit';
import { OfflineConsultation } from '../../utils/interfaces';
import { StatusTypes,ConsultationTypes } from '../../utils/enums';

interface OfflineConsultationsState {
    consultations: OfflineConsultation[];
    searchText: string;
    activeStatus: string;
    activeOption:ConsultationTypes;
    currentPage:number;
    lastDocId:string|null
}

const initialState: OfflineConsultationsState = {
    consultations: [],
    searchText: '',
    activeStatus: StatusTypes.NEW,
    activeOption: ConsultationTypes.OFFLINE,
    currentPage:1,
    lastDocId:null
};

export const offlineConsultationsSlice = createSlice({
    name: 'offlineConsultaions',
    initialState,
    reducers: {
        updateConsultationState(state, action) {
            const { id, status } = action.payload;
            const index = state.consultations.findIndex(item => item.id === id);
            if (index !== -1) {
                state.consultations[index].statusType = status;
            }
        },
        addOfflineConsultations(state, action) {
            state.consultations = action.payload
        },
        changeOfflineConsultationStatus(state, action) {
            state.activeStatus = action.payload
        },
        changeConsultationSearchText(state, action) {
            state.searchText = action.payload
        },
        updateLastDoc(state,action){
            state.lastDocId = action.payload
        },
        updatePageNumber(state,action){
            state.currentPage = action.payload
        },
    },
});

export const { updateConsultationState,
    addOfflineConsultations,
    changeConsultationSearchText,
    changeOfflineConsultationStatus,
    updatePageNumber,
    updateLastDoc
} = offlineConsultationsSlice.actions;
export default offlineConsultationsSlice.reducer;
