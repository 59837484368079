import { ContactPoland, Profile } from "../../../utils/interfaces";
import ProfilesItem from "./ProfilesItem";

interface ProfilesListProps {
  profiles: ContactPoland[];
}

const ProfilesList: React.FC<ProfilesListProps> = ({ profiles }) => {
  return (
    <div className="profiles-list">
      {profiles.map((profile, index) => (
        <ProfilesItem profile={profile.user || undefined || null } key={index} index={index + 1} />
      ))}
    </div>
  );
};

export default ProfilesList;
