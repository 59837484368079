import React from 'react'

interface LinkProps {
    text: string;
    fontSize?: number,
    color?: string,
    className?: string,
    onClick: () => void
}

const DownloadLink: React.FC<LinkProps> = ({ text, className, fontSize, onClick }) => {

    return (
        <div onClick={()=>onClick()} className={`link ${className}`} style={{ fontSize: fontSize, cursor:'pointer' }}>{text}</div>
    )
}

export default DownloadLink