import React from "react";


interface TicketInfoBlockProps {
    title: string;
    data?: string | number;
    children?: React.ReactElement;
    oneLiner?: boolean
}

const InsuranceInfoBlock: React.FC<TicketInfoBlockProps> = (
    { title, data, children, oneLiner }
) => {

    return (
        <div className={`${oneLiner ? 'insurance-infoblock-oneliner' : 'insurance-infoblock'}`}>
            <span className={`${oneLiner ? 'insurance-infoblock-oneliner-key' : 'insurance-infoblock-key'}`}>{title}</span>
            {data && <h6 className={`${oneLiner ? 'insurance-infoblock-oneliner-value' : 'insurance-infoblock-value'}`}>{data}</h6>}
            <>{children}</>
        </div>
    );
}

export default InsuranceInfoBlock

