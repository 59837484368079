import { useState, useEffect, ChangeEvent } from "react";
import {getTotalAmount, getTotalAmount2, paginationFunction} from "../firebase/firestore";
import ApplicationsList from "../components/ApplicationsList";
import Loader from "../components/Loader";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import SectionHeader from "../components/SectionHeader";
import { RootState } from "../redux/store";
import {GeneralStatuses, IMMIGRATION_VISAS_COLLECTION, TOURIST_VISAS_COLLECTION} from "../utils/consts";
import { Application } from "../utils/interfaces";
import { StatusTypes } from "../utils/enums";
import Pagination from "../components/Pagination";
import {
  addApplications,
  changeApplicationsSearchText,
  changeApplicationsActiveStatus,
  updateLastDoc,
  updatePageNumber
} from "../redux/slices/applicationsSlice";

const ApplicationsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const applications: Application[] = useAppSelector((state: RootState) => state.applications.applicationsList);
  const numberOfNewApplications: number = useAppSelector((state: RootState) => state.applications.applicationsList.filter((item) => item.statusType === StatusTypes.NEW).length);
  const activeStatus: string = useAppSelector((state: RootState) => state.applications.activeStatus);
  const searchText: string = useAppSelector((state: RootState) => state.applications.searchText);
  const lastDocId = useAppSelector((state)=>state.applications.lastDocId)
  const currentPage = useAppSelector((state)=>state.applications.currentPage)
  const [totalAmount, setTotalAmount] = useState(0)
  useEffect(() => {
    const fetchApplications = async () => {
      setIsLoading(true);
      const amount = await getTotalAmount2(TOURIST_VISAS_COLLECTION,activeStatus)
      setTotalAmount(amount)
      const {items ,newLastDocId } = await paginationFunction<Application>(TOURIST_VISAS_COLLECTION,lastDocId,activeStatus)
      dispatch(addApplications(items));
      setIsLoading(false);
    };
    fetchApplications();
  }, [activeStatus,currentPage]);


  const setActiveStatus = (status: string) => {
    dispatch(updatePageNumber(1))
    dispatch(updateLastDoc(null))
    dispatch(changeApplicationsActiveStatus(status));
  };

  const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeApplicationsSearchText(e.target.value));
  };

  return (
    <div className="content">
      <div className="applications">
        <h2 className="section-title">Заявки на Туристическую Визу</h2>
        <SectionHeader
          searchIsNumeric={true}
          searchPlaceholder="Поиск по номеру заявки..."
          activeStatus={activeStatus}
          numberOfNewItems={activeStatus === 'new' ? totalAmount : 0}
          statuses={GeneralStatuses}
          setActiveStatus={setActiveStatus}
          handleChangeSearchText={handleChangeSearchText}
        />
        <div className="applications-wrapper">
          {isLoading ? (
            <Loader />
          ) : (
            <ApplicationsList applications={
              applications.filter((item) => item.statusType === activeStatus && item.orderID.toString().includes(searchText))
            } />
          )}
        </div>
        <Pagination
            onChangePage={(item) => dispatch(updatePageNumber(item))}
            currentPage={currentPage}
            itemsPerPage={5}
            collectionName={TOURIST_VISAS_COLLECTION}
            currentStatus={activeStatus}
            totalItems={totalAmount}
        ></Pagination>
      </div>
    </div>
  );
};

export default ApplicationsPage;
