import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { RootState } from '../redux/store';
import {SpecialOffer} from '../utils/interfaces';
import { getTotalAmount2, paginationFunction} from '../firebase/firestore';
import { addSpecialOffers, changeSpecialOffersStatus, changeSearchText, updatePageNumber,updateLastDoc } from '../redux/slices/specialOffersSlice';
import SectionHeader from '../components/SectionHeader';
import {EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION, GeneralStatuses, SPECIAL_OFFERS_COLLECTION} from '../utils/consts';
import Loader from '../components/Loader';
import SpecialOffersList from '../components/SpecialOffersList';
import Pagination from "../components/Pagination";

const SpecialOffersPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const specialOffersList: SpecialOffer[] = useAppSelector((state: RootState) => state.specialOffers.specialOffers)
    const activeStatus: string = useAppSelector((state: RootState) => state.specialOffers.activeStatus)
    const searchText: string = useAppSelector((state: RootState) => state.specialOffers.searchText)
    const currentPage = useAppSelector((state:RootState)=>state.specialOffers.currentPage)
    const lastDocId = useAppSelector((state:RootState)=>state.specialOffers.lastDocId)
    const [totalAmount,setTotalAmount] = useState(0)

    useEffect(() => {
        const fetchApplications = async () => {
            setIsLoading(true);
            const amount = await getTotalAmount2(SPECIAL_OFFERS_COLLECTION,activeStatus)
            setTotalAmount(amount)
            const {items ,newLastDocId } = await paginationFunction<SpecialOffer>(SPECIAL_OFFERS_COLLECTION,lastDocId,activeStatus)

            dispatch(addSpecialOffers(items));
            dispatch(updateLastDoc(newLastDocId))

            setIsLoading(false);
        };
        fetchApplications();
    }, [activeStatus, currentPage, dispatch]);

    console.log(activeStatus)
    const setActiveStatus = (status: string) => {
        dispatch(updatePageNumber(1))
        dispatch(updateLastDoc(null))
        dispatch(changeSpecialOffersStatus(status));
    };

    const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeSearchText(e.target.value));
    };
    console.log(specialOffersList);

    return (
        <div className="content">
            <div className="tickets">
                <h2 className="section-title">Специальные предложения</h2>
                <SectionHeader
                    searchIsNumeric={true}
                    searchPlaceholder="Поиск по номеру брони..."
                    activeStatus={activeStatus}
                    numberOfNewItems={activeStatus === 'new' ? totalAmount : 0}
                    statuses={GeneralStatuses}
                    setActiveStatus={setActiveStatus}
                    handleChangeSearchText={handleChangeSearchText}
                />
                <div className="tickets-wrapper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        // <SpecialOffersList specialOffsers={specialOffersList}></SpecialOffersList>
                        <SpecialOffersList specialOffsers={specialOffersList}></SpecialOffersList>
                    )}
                </div>
                <Pagination
                    onChangePage={(item) => dispatch(updatePageNumber(item))}
                    currentPage={currentPage}
                    itemsPerPage={5}
                    collectionName={SPECIAL_OFFERS_COLLECTION}
                    currentStatus={activeStatus}
                    totalItems={totalAmount}
                ></Pagination>
            </div>
        </div>
    )
}

export default SpecialOffersPage;