import AirTicketItem from "./AirTicketItem";
import { AirTicketBooking } from "../utils/interfaces";
import NotFound from "./NotFound";

interface AirTicketsListProps {
  tickets: AirTicketBooking[];
}

const AirTicketsList: React.FC<AirTicketsListProps> = ({
  tickets,
}) => {
  return (
    tickets.length > 0 ? <div className="tickets-grid">
      {tickets.map((ticket) => (
        <AirTicketItem key={ticket.orderID} ticket={ticket}></AirTicketItem>
      ))} 
    </div> : <NotFound/>
  );
};

export default AirTicketsList;
