import React, { useState, useEffect, ChangeEvent } from "react";
import {getAllAirTicketBookings, getTotalAmount2, paginationFunction} from "../firebase/firestore";
import Loader from "../components/Loader";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import SectionHeader from "../components/SectionHeader";
import { RootState } from "../redux/store";
import {  changeApplicationsSearchText } from "../redux/slices/applicationsSlice";
import {
    AIR_TICKET_BOOKINGS_COLLECTION,
    GeneralStatuses
} from "../utils/consts";
import {AirTicketBooking} from "../utils/interfaces";
import AirTicketsList from "../components/AirTicketsList";
import {
    changeSearchText,
    addAirTicketBookings,
    changeStatus,
    updateLastDoc,
    updatePageNumber,
} from "../redux/slices/airTicketsSlice";
import Pagination from "../components/Pagination";

const AirTicketBookingsPage:React.FC = () => {

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const airTicketBookings: AirTicketBooking[] = useAppSelector((state: RootState) => state.airTickets.ticketsList)
    const activeStatus = useAppSelector((state:RootState)=> state.airTickets.activeStatus)
    const currentPage = useAppSelector((state:RootState)=>state.airTickets.currentPage)
    const lastDocId = useAppSelector((state:RootState)=>state.airTickets.lastDocId)
    const [totalAmount,setTotalAmount] = useState(0)
    const searchText = useAppSelector((state:RootState)=>state.airTickets.searchText)

    useEffect(() => {
        const fetchApplications = async () => {
            setIsLoading(true);
            const amount = await getTotalAmount2(AIR_TICKET_BOOKINGS_COLLECTION,activeStatus)
            setTotalAmount(amount)
            const {items ,newLastDocId } = await paginationFunction<AirTicketBooking>(AIR_TICKET_BOOKINGS_COLLECTION,lastDocId,activeStatus)
            dispatch(updateLastDoc(newLastDocId))
            dispatch(addAirTicketBookings(items));
            setIsLoading(false);
        };
        fetchApplications();
    }, [activeStatus,currentPage]);

    const setActiveStatus = (status: string) => {
        dispatch(updatePageNumber(1))
        dispatch(updateLastDoc(null))
        dispatch(changeStatus(status));
    };

    const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeSearchText(e.target.value));
    };
    return (
        <div className="content">
            <div className="tickets">
                <h2 className="section-title">Бронирования авиабилетов</h2>
                <SectionHeader
                    searchIsNumeric={true}
                    searchPlaceholder="Поиск по номеру брони..."
                    activeStatus={activeStatus}
                    numberOfNewItems={activeStatus === 'new' ? totalAmount : 0}
                    statuses={GeneralStatuses}
                    setActiveStatus={setActiveStatus}
                    handleChangeSearchText={handleChangeSearchText}
                />
                <div className="tickets-wrapper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <AirTicketsList tickets={airTicketBookings.filter((item)=>item.statusType === activeStatus && item.orderID.toString().includes(searchText))}></AirTicketsList>
                        
                        // <ApplicationsList applications={
                        //     applications.filter((item) => item.statusType === activeStatus && item.orderID.toString().includes(searchText))
                        // } />
                    )}
                </div>
                <Pagination
                    onChangePage={(item) => dispatch(updatePageNumber(item))}
                    currentPage={currentPage}
                    itemsPerPage={5}
                    collectionName={AIR_TICKET_BOOKINGS_COLLECTION}
                    currentStatus={activeStatus}
                    totalItems={totalAmount}
                ></Pagination>
            </div>
        </div>
    )
}

export default AirTicketBookingsPage