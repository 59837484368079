import React, { useState, createContext,useContext } from 'react';
import { DocumentSnapshot } from 'firebase/firestore';

interface PaginationContextType {
  lastDoc: DocumentSnapshot | null;
  setLastDoc: (doc: DocumentSnapshot | null) => void;
}

const PaginationContext = createContext<PaginationContextType | undefined>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}

const PaginationProvider: React.FC<ProviderProps> = ({ children }) => {
  const [lastDoc, setLastDoc] = useState<DocumentSnapshot | null>(null);
  return (
    <PaginationContext.Provider value={{ lastDoc, setLastDoc }}>
      {children}
    </PaginationContext.Provider>
  );
};

// export const usePagination = () => {
//   const context = useContext(PaginationContext);
//   if (context === undefined) {
//     throw new Error('usePagination must be used within a PaginationProvider');
//   }
//   return context;
// };


export default PaginationProvider