import { createSlice } from '@reduxjs/toolkit';
import { GreenCardApplication } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface GreenCardApplicationsState {
  greenCardApplicationsList: GreenCardApplication[];
  activeStatus: string;
  searchText: string;
  currentPage:number;
  lastDocId:string|null;
}

const initialState: GreenCardApplicationsState = {
  greenCardApplicationsList: [],
  activeStatus: StatusTypes.NEW,
  searchText: '',
  currentPage:1,
  lastDocId:null
};

export const greenCardApplicationsSlice = createSlice({
  name: 'greenCardApplications',
  initialState,
  reducers: {
    addGCApplications(state, action) {
      state.greenCardApplicationsList = action.payload;
    },
    updateGCApplicationsStatus(state, action) {
      const { id, status } = action.payload;
      const greenCardApplicationItem = state.greenCardApplicationsList.find(item => item.id === id);
      if (greenCardApplicationItem) {
        greenCardApplicationItem.statusType = status;
      }
    },
    changeGCApplicationsActiveStatus(state, action) {
      state.activeStatus = action.payload;
    },
    changeGCApplicationsSearchText(state, action) {
      state.searchText = action.payload;
    },
    updateLastDoc(state,action){
      state.lastDocId = action.payload
    },
    updatePageNumber(state,action){
      state.currentPage = action.payload
    },
    setGreenCardProfile(state,action){
      const {id,user} = action.payload
      const ind = state.greenCardApplicationsList.findIndex((item)=>item.id===id)
      if(ind!==-1){
        state.greenCardApplicationsList[ind].user = user
      }
    },
    setGreenCardQuestionnaire(state,action){
      const {id, questionnaires} = action.payload
      console.log(action.payload)
      const ind = state.greenCardApplicationsList.findIndex((item)=>item.id===id)
      if(ind!==-1){
        state.greenCardApplicationsList[ind].questionnaires = questionnaires
      }
      console.log(state.greenCardApplicationsList[ind].questionnaires)
    }
  },
});

export const {
    addGCApplications,
    updateGCApplicationsStatus,
    changeGCApplicationsActiveStatus,
    changeGCApplicationsSearchText,
    updateLastDoc,
    updatePageNumber,
    setGreenCardProfile,
    setGreenCardQuestionnaire,
} = greenCardApplicationsSlice.actions;
export default greenCardApplicationsSlice.reducer;
