import { EmiratesWorkVisaApplication, QuestionnaireAnswer} from "../../utils/interfaces";
import VisaInfoBlock from "./EWInfoBlock";
import {useEffect, useState} from "react";
import {getQuestionnaires} from "../../firebase/firestore";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {setEWApplicationQuestionnaire} from "../../redux/slices/emiratesWorkVisaSlice";
import Loader from "../Loader";

interface ApplicationsItemQuestionnaireProps {
    questionnaires: QuestionnaireAnswer[];
    application:EmiratesWorkVisaApplication
}

const EWorkVisaQUestionnaire: React.FC<
    ApplicationsItemQuestionnaireProps
> = ({ questionnaires,application }) => {
    const dispatch = useAppDispatch()
    const sectionTitle =
        application.questionnaireIDs && application.questionnaireIDs.length > 1 ? "Анкеты" : "Анкета";
    const [isLoading, setIsLoading] = useState(false)
    console.log(questionnaires);
    useEffect(() => {
        const getQuestionnairesData = async () =>{
            setIsLoading(true)
            const result = await getQuestionnaires(application.questionnaireIDs || [])
            dispatch(setEWApplicationQuestionnaire({id:application.id, questionnaires:result}))
            setIsLoading(false)
        }
        getQuestionnairesData()
    }, [dispatch]);
    return (
        <div className="workvisas__questionnaire">
            <h6 className="workvisas__title ">{sectionTitle}</h6>
            {isLoading ? <div className="spinner-container">
                <Loader></Loader>
            </div>
                :
                questionnaires &&
                questionnaires.map((questionnaire, index) => (
                    <>
                        <div
                            className="workvisas__questionnaire__grid"
                            key={index}
                        >
                            {questionnaire.applicantQuestionnaireAnswers.map(
                                (item, index) => (
                                    <VisaInfoBlock
                                        title={item.question}
                                        data={item.answer || 'Пусто'}
                                        key={index}
                                    />
                                ),
                            )}
                        </div>
                        {index + 1 !== questionnaires.length && (
                            <div className="applications-item__line"></div>
                        )}
                    </>
                ))
            }
        </div>
    );
};

export default EWorkVisaQUestionnaire;
