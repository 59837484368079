
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../hooks/reduxHooks";
import { EmiratesWorkVisaApplication, SpecialOffer } from "../../utils/interfaces";
import StatusChangeButton from '../StatusChangeButton'
import { SPECIAL_OFFERS_COLLECTION } from '../../utils/consts'
import { updateTicketStatus } from '../../redux/slices/specialOffersSlice'
import { Status } from "../../utils/interfaces";
import SpecialOfferInfoBlock from "./SpecialOfferInfoBlock";
import ApplicationsItemBooleanToggle from "../ApplicationsItemBooleanToggle";

export const EmiratesWorkStatuses: Status[] = [
    {
        eng: 'new',
        rus: 'Новые'
    },
    {
        eng: 'inProcess',
        rus: 'В процессе'
    },
    {
        eng: 'won',
        rus: 'Одобренные'
    },
    {
        eng: 'lost',
        rus: 'Не одобренные'
    }
];

interface EWorkingVisaProps {
    offer: SpecialOffer
}

const SpecialOfferGeneralInfo: React.FC<EWorkingVisaProps> = ({ offer }) => {
    const activeStatus: string = useAppSelector(
        (state: RootState) => state.greenCardApplications.activeStatus,
    );
    console.log(offer.bookingToCountry);

    return (
        <div className="offers-item__general">
            <h4 className="offers-item__general__title">Общая информация</h4>
            <div className="offers-item__general__infoContainer">
                <SpecialOfferInfoBlock title="Пункт назначения" data={offer.bookingToCountry || 'пусто'}></SpecialOfferInfoBlock>
                <SpecialOfferInfoBlock title="Итоговая цена" data={offer.cost || 'пусто'}></SpecialOfferInfoBlock>
                {/* <TicketInfoBlock title="Оплачено">
                    <ApplicationsItemBooleanToggle
                        id={application.id}
                        isPaid={application.status}
                        collection={AIR_TICKET_BOOKINGS_COLLECTION} />
                </TicketInfoBlock> */}
                {/* <div className="tickets-item__body__block">
                    <span className="tickets-item__body__block-key">{title}</span>
                    <h6 className="tickets-item__body__block-value">{data}</h6>
                </div> */}
                <SpecialOfferInfoBlock title="Оплачено" oneLiner={false}>
                    <ApplicationsItemBooleanToggle
                        id={offer.id}
                        isPaid={offer.isPaid}
                        collection={SPECIAL_OFFERS_COLLECTION} />
                </SpecialOfferInfoBlock>
                
                <StatusChangeButton
    
                    id={offer.id}
                    isStatusType={false}
                    collection={SPECIAL_OFFERS_COLLECTION}
                    activeStatus={''}
                    statuses={EmiratesWorkStatuses}
                    bottomOffset={8}
                    topOffset={3}
                    dispatchMethod={updateTicketStatus}
                />
            </div>

        </div>
    );
}
export default SpecialOfferGeneralInfo;