import { createSlice } from '@reduxjs/toolkit';
import { Application } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface ApplicationsState {
  applicationsList: Application[];
  activeStatus: string;
  searchText: string;
  currentPage: number,
  lastDocId:string|null,
  totalAmount:number,
}

const initialState: ApplicationsState = {
  applicationsList: [],
  activeStatus: StatusTypes.NEW,
  searchText: '',
  currentPage: 1,
  lastDocId:null,
  totalAmount:0
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    addApplications(state, action) {      
      state.applicationsList = action.payload
    },
    updateApplicationsStatus(state, action) {
      const { id, status } = action.payload;
      const applicationItem = state.applicationsList.find(item => item.id === id);
      if (applicationItem) {
        applicationItem.status = +status;
      }
    },
    updateApplicationsStatusType(state, action) {
      const { id, statusType } = action.payload;
      const applicationItem = state.applicationsList.find(item => item.id === id);
      if (applicationItem) {
        applicationItem.statusType = statusType;
      }
    },
    changeApplicationsActiveStatus(state, action) {
      state.activeStatus = action.payload;
    },
    changeApplicationsSearchText(state, action) {
      state.searchText = action.payload;
    },
    updateLastDoc(state,action){
      state.lastDocId = action.payload
    },
    updatePageNumber(state,action){
      state.currentPage = action.payload
    },
    setApplicationProfile(state,action){
      const {id,user} = action.payload
      const ind = state.applicationsList.findIndex((item)=>item.id===id)
      if(ind!==-1){
        state.applicationsList[ind].user = user
      }
    },
    setApplicationQuestionnaire(state,action){
      const {id, questionnaires} = action.payload
      console.log(action.payload)
      const ind = state.applicationsList.findIndex((item)=>item.id===id)
      if(ind!==-1){
        state.applicationsList[ind].questionnaires = questionnaires
      }
    }
  },
});

export const { addApplications, updateApplicationsStatus, updateApplicationsStatusType, changeApplicationsActiveStatus, changeApplicationsSearchText,updatePageNumber,updateLastDoc,setApplicationProfile,setApplicationQuestionnaire } = applicationsSlice.actions;
export default applicationsSlice.reducer;
