import { useState, useEffect, useRef } from "react";
import { ChangeEvent, KeyboardEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { StatusTypes } from "../utils/enums";
import { NUMBER_REGEXP } from "../utils/consts";
import { SelectOption, Status } from "../utils/interfaces";

interface SectionHeaderProps {
  searchIsNumeric: boolean;
  searchPlaceholder: string;
  activeStatus: string;
  numberOfNewItems: number;
  statuses: Status[];
  setActiveStatus: (value: string) => void;
  hasSearch?: boolean;

  hasSelect?: boolean;
  selectOptions?: SelectOption[];
  selectedOption?: string;
  setSelectedOption?: (value: string) => void;


  handleChangeSearchText?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  searchIsNumeric,
  searchPlaceholder,
  activeStatus,
  numberOfNewItems,
  statuses,
  setActiveStatus,
  hasSearch = true,
  handleChangeSearchText,
  hasSelect,
  selectOptions,
  selectedOption,
  setSelectedOption
}) => {
  const [showSelect, setShowSelect] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference to the dropdown for detecting outside clicks

  const toggleDropdown = () => setShowSelect(!showSelect);
  const openDropDown = () => setShowSelect(true)
  useEffect(() => {
    if (hasSelect) {
      const handleClickOutside = (event: React.MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setShowSelect(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside as any);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside as any);
      };
    }
  }, [dropdownRef]);

  return (
    <div className="section-header">
      <div className="section-tabs">{
        statuses.map((status, ind) => (
          <div
            key={ind}
            className={
              activeStatus === status.eng
                ? "section-tabs__item section-tabs__item-active"
                : "section-tabs__item"
            }
            onClick={() => setActiveStatus(status.eng)}>
            {status.rus}
            {status.eng === StatusTypes.NEW && numberOfNewItems > 0 && (
              <div className="section-tabs__item-amount">{numberOfNewItems}</div>
            )}
          </div>
        ))
      }</div>
      {
        hasSearch && <div className="section-search">
          <input
            type="text"
            className="section-search__input"
            placeholder={searchPlaceholder}
            onChange={handleChangeSearchText}
            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
              if (searchIsNumeric && !NUMBER_REGEXP.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
      }
      {
        hasSelect && <div className="section-select">
          <div className="select__activator" ref={dropdownRef} onClick={toggleDropdown}>
            <span>{selectedOption}</span>
            {
              showSelect &&
              <div className="select__dropdown">
                {selectOptions?.map((option) => (
                  <div className='select__option' onClick={() => { setSelectedOption!(option.value) }}>{option.rus}</div>
                ))}
              </div>
            }
          </div>
              

          {/* <select>
          
          </select> */}
        </div>

      }
    </div>
  );
};

export default SectionHeader;