import React, { useState } from 'react'
import { EmiratesWorkVisaApplication, OfflineConsultation } from '../../utils/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faClock, IconDefinition, faUser } from '@fortawesome/free-solid-svg-icons'
import OfflineConsultationGeneralInfoBlock from './GeneralInfoblock/Offline'


interface OfflineConsultationProps {
    consultation: OfflineConsultation
}

interface Data {
    icon: IconDefinition;
}

const data: Data[] = [
    {
        icon: faInfo,
    },
    // {
    //     icon: faUser,
    // },
];

const OfflineConsultationComponent: React.FC<OfflineConsultationProps> = (
    { consultation }
) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)

    
    return (
        <div className="consultations-item">
            <div className="consultations-item__btn-list">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`consultations-item__btn-item ${activeIndex === index && "consultations-item__btn-item-active"
                            }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <FontAwesomeIcon icon={item.icon} />
                    </div>
                ))}
            </div>
            <div className="consultations-item__header">
                <div className="consultations-item__header-item">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <h6 className="consultations-item__header-item-text">{consultation.date}</h6>
                </div>
                <div className="consultations-item__header-item">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <h6 className="consultations-item__header-item-text">{consultation.date}</h6>
                </div>
            </div>
            <div className="consultations-item__body">
                {
                    activeIndex === 0 ? <OfflineConsultationGeneralInfoBlock consultation={consultation} /> :
                    <></>
                }
            </div>
            {/* {consultation.status === StatusTypes.NEW && <div className="tickets-item__new">Новое</div>} */}
        </div>
    )
}

export default OfflineConsultationComponent