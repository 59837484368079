import React, { useState } from 'react'
import { SpecialOffer } from '../../utils/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag, faInfo, faUsers, faClock, IconDefinition, faFileCircleQuestion, faTag } from '@fortawesome/free-solid-svg-icons'
import { StatusTypes } from '../../utils/enums'

import { Status } from '../../utils/interfaces'
import SpecialOfferGeneralInfo from './GeneralInfoBlock'
import SpecialOfferApplicants from './ApplicantsBlock'

interface EmiratesWorkVisaApplicationProps {
    specialOffer: SpecialOffer
}

interface Data {
    icon: IconDefinition;
}

const data: Data[] = [
    {
        icon: faInfo,
    },
    {
        icon: faUsers,
    },
];
const SpecialOfferItem: React.FC<EmiratesWorkVisaApplicationProps> = (
    { specialOffer }
) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    return (
        <div className="offers-item">
            <div className="offers-item__btn-list">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`offers-item__btn-item ${activeIndex === index && "offers-item__btn-item-active"
                            }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <FontAwesomeIcon icon={item.icon} />
                    </div>
                ))}
            </div>
            <div className="offers-item__header">
                <div className="offers-item__header-item">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <h6 className="offers-item__header-item-text">{specialOffer.createdAt}</h6>
                </div>
                <div className="offers-item__header-item">
                    <FontAwesomeIcon icon={faTag}></FontAwesomeIcon>
                    <h6 className="offers-item__header-item-text">{specialOffer.orderID}</h6>
                </div>
            </div>
            <div className="offers-item__body">
                {
                    activeIndex === 0 ?
                        <SpecialOfferGeneralInfo offer={specialOffer}></SpecialOfferGeneralInfo>
                        : <SpecialOfferApplicants applicants={specialOffer.clients}></SpecialOfferApplicants>
                }
            </div>

            {specialOffer.statusType === StatusTypes.NEW && <div className="offers-item__new">Новое</div>}
        </div>
    )
}

export default SpecialOfferItem