import {Application, Profile} from "../../utils/interfaces";
import ApplicationsItemBlock from "./ApplicationsItemBlock";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {useEffect, useState} from "react";
import { getUser} from "../../firebase/firestore";
import {setApplicationProfile, } from "../../redux/slices/applicationsSlice";

interface ApplicationsItemProfileProps {
  phoneNumber: string;
  user: Profile | null;
  application:Application
}

const ApplicationsItemProfile: React.FC<ApplicationsItemProfileProps> = ({
  phoneNumber, user,application
}) => {
  const dispatch = useAppDispatch()
  const [isLoading,setIsLoading] = useState(false)

  useEffect(() => {
    const getQuestionnairesData = async () =>{
      setIsLoading(true)
      const result = await getUser(application.userID)
      dispatch(setApplicationProfile({id:application.id, user:result}))
      setIsLoading(false)
    }
    getQuestionnairesData()
  }, [dispatch]);

  return user ? (
    <div className="applications-item__section">
      <h6 className="applications-item__title">Данные пользователя</h6>
      <div className="applications-item__grid">
        <div className="applications-item__grid-part">
          <ApplicationsItemBlock title="Имя пользователя" value={application.user?.username} />
          <ApplicationsItemBlock title="E-mail" value={application.user?.email} />
          <ApplicationsItemBlock title="Телефон" value={application.user?.phoneNumber ? application.user?.phoneNumber : phoneNumber} />
        </div>
        <div className="applications-item__grid-part">
          <ApplicationsItemBlock title="Адрес" value={application.user?.address} />
          <ApplicationsItemBlock title="Квартира" value={application.user?.apartment} />
        </div>
      </div>
    </div>
  ) : <div className="applications-item__section">
    <h6 className="applications-item__title">Данные пользователя</h6>
    <div className="applications-item__grid">
      <div className="applications-item__grid-part">
        <ApplicationsItemBlock title="Телефон" value={phoneNumber} />
      </div>
    </div>
  </div>;
};

export default ApplicationsItemProfile;
