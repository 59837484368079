import { createSlice } from '@reduxjs/toolkit';
import { ContactPoland, Profile } from '../../utils/interfaces';

interface PolandContactsState {
    profilesList: ContactPoland[];
    activeProfile: Profile | null;
    sort: string;
    searchText: string;
    isByEmail: boolean;
}

const initialState: PolandContactsState = {
    profilesList: [],
    activeProfile: null,
    sort: '-name',
    searchText: '',
    isByEmail: false
};

export const profilesSlice = createSlice({
    name: 'polandContacts',
    initialState,
    reducers: {
        addProfiles(state, action) {
            state.profilesList = action.payload;
        },
        toggleActiveProfile(state, action) {
            if (action.payload) {
                state.activeProfile = action.payload
            } else {
                state.activeProfile = null;
            }
        },
        changeProfilesSort(state, action) {
            state.sort = action.payload;
        },
        changeProfilesSearchText(state, action) {
            state.searchText = action.payload;
        },
        toggleProfilesIsByEmail(state, action) {
            state.isByEmail = action.payload;
        }
    },
});

export const { addProfiles, toggleActiveProfile, changeProfilesSort, changeProfilesSearchText, toggleProfilesIsByEmail } = profilesSlice.actions;
export default profilesSlice.reducer;
