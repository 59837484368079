import { OnlineConsultation } from '../../utils/interfaces';
import NotFound from "../NotFound";
import OnlineConsultationComponent from "./OnlineConsultationItem";

interface OnlineConsultationListProps {
    consultations: OnlineConsultation[];
}

const OnlineConsultationList: React.FC<OnlineConsultationListProps> = ({
    consultations,
}) => {
    console.log();

    return (
        consultations.length > 0 ? <div className="workvisas-grid">
            {consultations.map((item, index) => (
                <OnlineConsultationComponent consultation={item} key={index}></OnlineConsultationComponent>
            ))}
        </div> : <NotFound />
    );
};

export default OnlineConsultationList;