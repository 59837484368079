import React, {useEffect, useState} from "react";
import {EmiratesWorkVisaApplication, Profile} from "../../utils/interfaces";
import ApplicantImage from "../GCApplicationsItem/ApplicantImage";
import VisaInfoBlock from "./EWInfoBlock";
import Link from "../Link";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {getUser} from "../../firebase/firestore";
import {setEWApplicationProfile} from "../../redux/slices/emiratesWorkVisaSlice";
import Loader from "../Loader";

interface EWApplicantProps {
    application:EmiratesWorkVisaApplication
    applicant: Profile | null | undefined;
    cvLink?:string;
}


const EWApplicantBlock: React.FC<EWApplicantProps> = (
    { applicant , cvLink,application }
) => {
    const dispatch = useAppDispatch()
    const [isLoading,setIsLoading] = useState(false)
    useEffect(() => {
        const getProfile = async () =>{
            setIsLoading(true)
            const result = await getUser(application.userID)
            dispatch(setEWApplicationProfile({id:application.id, user:result}))
            setIsLoading(false)
        }
        getProfile()
    }, [dispatch]);
    console.log(applicant);
    return (
        <div className="workvisas-item__applicant">
            <h4 className="workvisas-item__applicant__title">Данные заявителя</h4>
            {isLoading ? <div className='spinner-container'>
                    <Loader></Loader>
            </div> :
                application.user ?
                    <div className="workvisas-item__applicant__grid">
                        <div className="workvisas-item__applicant__grid__column">
                            <VisaInfoBlock title="Фото"  children={<ApplicantImage photoLink={application.user?.photoLink || ''}></ApplicantImage>} />
                        </div>
                        <div className="workvisas-item__applicant__grid__column">
                            <VisaInfoBlock title="Полное имя :" data={`${application.user?.username || 'Пусто'}`} oneLiner/>
                            <VisaInfoBlock title="Адрес :" data={`${application.user?.address || 'Пусто'}`} oneLiner />
                            <VisaInfoBlock title="Номер квартиры :" data={`${application.user?.apartment || 'Пусто'}`}  oneLiner/>
                            <VisaInfoBlock title="Номер телефона :" data={`${application.user?.phoneNumber || 'Пусто'}`}  oneLiner/>
                            <VisaInfoBlock title="Электронная почта :" data={`${application.user?.email || 'Пусто'}`}  oneLiner/>
                            <Link onClick={()=>{window.open(cvLink)}}  className="workvisas-infoblock-link" text="Резюме"></Link>
                        </div>
                    </div>
                    : <h1>
                        Заявитель не оставил информацию о себе
                    </h1>
            }




        </div>
    )
}

export default EWApplicantBlock