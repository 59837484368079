import React, { useState, useEffect, ChangeEvent } from "react";
import {getTotalAmount2, paginationFunction} from "../firebase/firestore";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { RootState } from "../redux/store";
import SectionHeader from "../components/SectionHeader";
import Loader from "../components/Loader";
import GreenCardApplicationsList from "../components/GCApplicationsList";
import { GreenCardApplication, Status} from "../utils/interfaces";
import { GREEN_CARDS_APPLICATIONS_COLLECTION} from "../utils/consts";
import {
  addGCApplications,
  updateLastDoc,
  updatePageNumber,
  changeGCApplicationsActiveStatus,
  changeGCApplicationsSearchText
} from "../redux/slices/greenCardApplicationsSlice";
import Pagination from "../components/Pagination";

export const GreenCardsStatuses: Status[] = [
  {
    eng: 'new',
    rus: 'Новые'
  },
  {
    eng: 'process',
    rus: 'В процессе'
  },
  {
    eng: 'won',
    rus: 'Одобренные'
  },
  {
    eng: 'lost',
    rus: 'Не одобренные'
  }
];

const GreenCardsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const greenCardApplications = useAppSelector((state: RootState) => state.greenCardApplications.greenCardApplicationsList);
  const numberOfNewGCApplications: number = useAppSelector((state: RootState) => state.greenCardApplications.greenCardApplicationsList.filter((item) => item.statusType === "new").length);
  const activeStatus: string = useAppSelector((state: RootState) => state.greenCardApplications.activeStatus);
  const searchText: string = useAppSelector((state: RootState) => state.greenCardApplications.searchText);
  const currentPage = useAppSelector((state:RootState)=>state.greenCardApplications.currentPage);
  const lastDocId = useAppSelector((state:RootState)=>state.greenCardApplications.lastDocId)
  const [totalAmount ,setTotalAmount] = useState(0)




  useEffect(() => {
    const fetchApplications = async () => {
      setIsLoading(true);
      const amount = await getTotalAmount2(GREEN_CARDS_APPLICATIONS_COLLECTION,activeStatus)
      setTotalAmount(amount)
      const {items ,newLastDocId } = await paginationFunction<GreenCardApplication>(GREEN_CARDS_APPLICATIONS_COLLECTION,lastDocId,activeStatus)
      dispatch(updateLastDoc(newLastDocId))
      dispatch(addGCApplications(items));
      setIsLoading(false);
    };
    fetchApplications();
  }, [activeStatus,currentPage]);
  const setActiveStatus = (status: string) => {
    dispatch(updatePageNumber(1))
    dispatch(updateLastDoc(null))
    dispatch(changeGCApplicationsActiveStatus(status));
  };

  const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeGCApplicationsSearchText(e.target.value));
  };
  return (
    <div className="content">
      <div className="gca">
        <h2 className="section-title">Заявки на Грин-карту</h2>
        <SectionHeader
          searchIsNumeric={true}
          searchPlaceholder="Поиск по номеру заявки..."
          activeStatus={activeStatus}
          numberOfNewItems={activeStatus === 'new' ? totalAmount :0 }
          statuses={GreenCardsStatuses}
          setActiveStatus={setActiveStatus}
          handleChangeSearchText={handleChangeSearchText}
        />
        <div className="gca-wrapper">{
          isLoading ? <Loader/> : <GreenCardApplicationsList greenCardApplications={greenCardApplications.filter((item) => item.statusType === activeStatus && item.orderID.toString().includes(searchText))}/>
        }</div>
        {greenCardApplications.length > 0 && <Pagination
            onChangePage={(item) => dispatch(updatePageNumber(item))}
            currentPage={currentPage}
            itemsPerPage={5}
            collectionName={GREEN_CARDS_APPLICATIONS_COLLECTION}
            currentStatus={activeStatus}
            totalItems={totalAmount}
        ></Pagination> }

      </div>
    </div>
  );
}

export default GreenCardsPage;