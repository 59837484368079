import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { RootState } from '../redux/store';
import {OfflineConsultation} from '../utils/interfaces';
import { getTotalAmount2, paginationFunction} from '../firebase/firestore';
import SectionHeader from '../components/SectionHeader';
import {GeneralStatuses, OFFLINE_CONSULTATIONS_COLLECTION, TOURIST_VISAS_COLLECTION} from '../utils/consts';
import Loader from '../components/Loader';
import {
    addOfflineConsultations,
    changeOfflineConsultationStatus,
    updateLastDoc,
    updatePageNumber
} from '../redux/slices/offlineConsultationSlice';
import OfflineConsultationList from '../components/Consultations/OfflineConsultationList';
import { StatusTypes } from '../utils/enums';
import { changeConsultationSearchText } from '../redux/slices/offlineConsultationSlice';
import Pagination from "../components/Pagination";

const OfflineConsultaionsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [activeStatus, setActiveStatus] = useState<StatusTypes>(StatusTypes.NEW)

    const offlineConsultationsList: OfflineConsultation[] = useAppSelector((state: RootState) => state.offlineConsultations.consultations)
    const searchText: string = useAppSelector((state: RootState) => state.offlineConsultations.searchText)
    const currentPage = useAppSelector((state:RootState)=>state.offlineConsultations.currentPage)
    const lastDocId = useAppSelector((state:RootState)=>state.offlineConsultations.lastDocId)
    const activeStatus = useAppSelector((state:RootState)=>state.offlineConsultations.activeStatus)
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        const fetchConsultations = async () => {
            setIsLoading(true);
            const amount = await getTotalAmount2(OFFLINE_CONSULTATIONS_COLLECTION,activeStatus)
            setTotalAmount(amount)
            const {items ,newLastDocId } = await paginationFunction<OfflineConsultation>(OFFLINE_CONSULTATIONS_COLLECTION,lastDocId,activeStatus)
            dispatch(updateLastDoc(newLastDocId))
            dispatch(addOfflineConsultations(items));
            setIsLoading(false);
        };
        fetchConsultations();
    }, [activeStatus,currentPage]);

    const changeActiveStatus = (value: string) => {
        dispatch(updatePageNumber(1))
        dispatch(updateLastDoc(null))
        dispatch(changeOfflineConsultationStatus(value))
    }

    const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeConsultationSearchText(e.target.value));
    };


    return (
        <div className="content">
            <div className="workvisas">
                <h2 className="workvisas__title">Список оффлайн консультаций</h2>
                <SectionHeader
                    searchIsNumeric={false}
                    searchPlaceholder="Поиск по имени..."
                    activeStatus={activeStatus}
                    numberOfNewItems={
                        activeStatus === 'new' ? totalAmount : 0
                    }
                    statuses={GeneralStatuses}
                    setActiveStatus={changeActiveStatus}
                    handleChangeSearchText={handleChangeSearchText}
                    hasSearch={true}
                />
                <div className="tickets-wrapper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <OfflineConsultationList consultations={offlineConsultationsList}></OfflineConsultationList>
                    )}
                </div>
                <Pagination
                    onChangePage={(item) => dispatch(updatePageNumber(item))}
                    currentPage={currentPage}
                    itemsPerPage={5}
                    collectionName={OFFLINE_CONSULTATIONS_COLLECTION}
                    currentStatus={activeStatus}
                    totalItems={totalAmount}
                ></Pagination>
            </div>
        </div>
    )
}

export default OfflineConsultaionsPage;