import React, { useEffect, useState } from "react";
import { getTotalNumberOfItems } from "../firebase/firestore";

// import { IoIosArrowDown } from "react-icons/io";
// import { IoIosArrowUp } from "react-icons/io";
interface PaginationProps {
    currentPage: number,
    onNextPageClick?: (page: number) => void;
    onPrevPageClick?: (page: number) => void;
    onChangePage: (page: number) => void
    itemsPerPage: number,
    collectionName: string,
    currentStatus: string,
    totalItems:number
}

const Pagination: React.FC<PaginationProps> = (props) => {
    const { currentPage, onNextPageClick, onPrevPageClick, itemsPerPage, currentStatus, onChangePage, collectionName } = props;
    const totalPages = Math.ceil(props.totalItems / itemsPerPage);
    const maxPageNumbersToShow = Math.min(10, totalPages); // Максимум 10 или меньше, если страниц меньше

    let visiblePagesStart = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    let visiblePagesEnd = Math.min(visiblePagesStart + maxPageNumbersToShow - 1, totalPages);

    // Корректировка, чтобы всегда показывать maxPageNumbersToShow страниц, если возможно
    if (visiblePagesEnd - visiblePagesStart + 1 < maxPageNumbersToShow) {
        visiblePagesStart = Math.max(visiblePagesEnd - maxPageNumbersToShow + 1, 1);
    }
    const onPageClick = (pageNumber: number) => {
        onChangePage(pageNumber);
    };

    return (
        <div className={'paginator'}>

            {/*<button className="paginator__btn" onClick={() => { }}>*/}
            {/*    {'<'}*/}
            {/*</button>*/}

            <div className="pageNumberContainer">
                {Array.from({length: visiblePagesEnd - visiblePagesStart + 1}, (_, idx) => visiblePagesStart + idx).map(pageNumber => (
                    <button
                        key={pageNumber}
                        className={`pageNumber ${currentPage === pageNumber ? 'active' : ''}`}
                        onClick={() => onChangePage(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                ))}
            </div>
            {/*<button className="paginator__btn" onClick={() => {*/}
            {/*}}>*/}
            {/*    {'>'}*/}
            {/*</button>*/}

        </div>
    );
};

export default Pagination