import { createSlice } from '@reduxjs/toolkit';
import { AirTicketBooking, Insurance } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface InsuranceState {
    insuranceList: Insurance[];
    searchText: string;
    activeStatus:string;
    currentPage:number;
    lastDocId:string|null,
    totalAmount:number,
}

const initialState: InsuranceState = {
    insuranceList: [],
    searchText: '',
    activeStatus:"new",
    currentPage: 1,
    lastDocId:null,
    totalAmount:0
};

export const insuranceSlice = createSlice({
    name: 'insurance',
    initialState,
    reducers: {
        updateInsuranceStatus(state, action){
            const {id,statusType} = action.payload
            const insurance = state.insuranceList.find(item => item.id === id)
            if(insurance)insurance.statusType = statusType
        },
        addInsurances(state,action){
          state.insuranceList = action.payload    
        },
        changeInsuranceStatus(state, action) {
            state.activeStatus =action.payload
        },
        updateLastDoc(state,action){
            state.lastDocId = action.payload
        },
        updatePageNumber(state,action){
            state.currentPage = action.payload
        },
        changeSearchText(state,action){
            state.searchText = action.payload
        }
    },
});

export const {
    changeSearchText,
    addInsurances,
    updateInsuranceStatus,
    changeInsuranceStatus,
    updateLastDoc,
    updatePageNumber
} = insuranceSlice.actions;
export default insuranceSlice.reducer;
