import { doc, where, collection, getDocs, updateDoc, getDoc, DocumentReference, Firestore, deleteField, query, orderBy, DocumentSnapshot, limit, startAfter, count } from "firebase/firestore";
import { ref, getDownloadURL } from '@firebase/storage'
import { firestore } from "./config";
import { Application, Feedback, GreenCardApplication, QuestionnaireAnswer, Profile, ImmigrationVisa, AirTicketBooking, EmiratesWorkVisaApplication, ApplicantQuestionnaireAnswer, OfflineConsultation, OnlineConsultation, Insurance, ContactPoland } from "../utils/interfaces";
import { TOURIST_VISAS_COLLECTION, FEEDBACK_COLLECTION, GREEN_CARDS_APPLICATIONS_COLLECTION, PROFILES_COLLECTION, IMMIGRATION_VISAS_COLLECTION, AIR_TICKET_BOOKINGS_COLLECTION, EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION, SPECIAL_OFFERS_COLLECTION, OFFLINE_CONSULTATIONS_COLLECTION, ONLINE_CONSULTATIONS_COLLECTION, INSURANCE_COLLECTION, TO_CONTACT_FOR_POLAND_COLLECTION } from "../utils/consts";
import { StatusTypes } from "../utils/enums";
import { SpecialOffer } from "../utils/interfaces";
import { get } from "firebase/database";
import {log} from "node:util";


export const getAllApplications = async () => {
  const applicationsRef = collection(firestore, TOURIST_VISAS_COLLECTION);
  const querySnapshot = await getDocs(query(applicationsRef, orderBy("createdAt", "desc")));

  const applications: Application[] = [];

  for (const doc of querySnapshot.docs) {
    const application = doc.data() as Application;

    if (application.questionnaireIDs) {
      const questionnaires = await getQuestionnaires(application.questionnaireIDs);
      application.questionnaires = questionnaires;
    }

    if (application.userID) {
      const user = await getUser(application.userID);
      application.user = user;
    }

    if (!application.hasOwnProperty('statusType')) {
      application.statusType = StatusTypes.NEW;
    }

    const { questionnaireIDs, ...rest } = application;
    applications.push(rest);
  }

  return applications;
}

export const getUser = async (userId: string) => {
  const userRef = doc(firestore, PROFILES_COLLECTION, userId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    const user = userSnap.data() as Profile;
    return user;
  }
  return null;
}

export const getQuestionnaires = async (questionnaireIDs: DocumentReference[]) => {
  const questionnaires: QuestionnaireAnswer[] = [];
  for (const questionnaireID of questionnaireIDs) {
    console.log(questionnaireID)
    const questionnaireDoc = await getDoc(questionnaireID);
    console.log(questionnaireDoc)
    if (questionnaireDoc.exists()) {
      const questionnaire = questionnaireDoc.data() as QuestionnaireAnswer;
      questionnaires.push(questionnaire);
    }
  }
  return questionnaires;
}

export const updateStatus = async (id: string, collection: string, status: string | number, isStatusType: boolean) => {
  const docRef = doc(firestore, collection, id);
  await updateDoc(docRef, isStatusType ? {
    statusType: status
  } : {
    status
  });
}


export const updateApplicationIsPaid = async (id: string, isPaid: boolean, collection: string) => {
  const applicationRef = doc(firestore, collection, id);
  const updateData = isPaid
    ? {
      isPaid: true,
      paymentTime: Math.floor(Date.now() / 1000)
    }
    : {
      isPaid: false,
      paymentTime: deleteField()
    };

  await updateDoc(applicationRef, updateData);
}


export const updateApplicationInterviewDate = async (id: string, date: string) => {
  const applicationRef = doc(firestore, TOURIST_VISAS_COLLECTION, id);
  await updateDoc(applicationRef, {
    interViewDate: date
  });
}

export const getFeedback = async () => {
  const feedbackRef = collection(firestore, FEEDBACK_COLLECTION);
  const querySnapshot = await getDocs(feedbackRef);

  const feedback: Feedback[] = [];

  querySnapshot.forEach((doc) => {
    const item = doc.data() as Feedback;
    item.id = doc.id;
    feedback.push(item);
  });

  return feedback;
}

export const getAllProfiles = async () => {
  const usersRef = collection(firestore, PROFILES_COLLECTION);
  const querySnapshot = await getDocs(usersRef);

  const users: Profile[] = [];

  querySnapshot.forEach((doc) => {
    const user = doc.data() as Profile;
    users.push(user);
  })

  return users;
}

export const getAllGreenCardApplications = async () => {
  const greenCardApplicationsRef = collection(firestore, GREEN_CARDS_APPLICATIONS_COLLECTION);
  const querySnapshot = await getDocs(query(greenCardApplicationsRef, orderBy("createdAt", "desc")));

  const greenCardApplications: GreenCardApplication[] = [];

  for (const doc of querySnapshot.docs) {
    const greenCardApplication = doc.data() as GreenCardApplication;
    greenCardApplication.id = doc.id;

    if (greenCardApplication.questionnaireIDs) {
      const questionnaires = await getQuestionnaires(greenCardApplication.questionnaireIDs);
      greenCardApplication.questionnaires = questionnaires;
    }

    if (greenCardApplication.userID) {
      const user = await getUser(greenCardApplication.userID);
      greenCardApplication.user = user;
    }

    if (greenCardApplication.statusType === 'progress') {
      greenCardApplication.statusType = StatusTypes.NEW;
    }

    const { questionnaireIDs, ...application } = greenCardApplication;
    greenCardApplications.push(application);
  }

  return greenCardApplications;
}

export const getTotalNumberOfItems = async (status: string, collectionName: string) => {
  const collectionRef = collection(firestore,collectionName)
  const filter = query(collectionRef, where("status", "==", status))
  return (await getDocs(filter)).size
}

export const paginationFunction = async<T>(collectionName:string, lastDocId:string|null, status:string):Promise<{items:T[], newLastDocId:string|undefined}> =>{
  const collectionRef = collection(firestore, collectionName);
  let paginationQuery
  if(lastDocId){
    const lastDocRef = doc(firestore, collectionName, lastDocId)
    const lastDoc = await getDoc(lastDocRef)
    paginationQuery = query(
        collectionRef,
        where("statusType", "==", status),
        orderBy("statusType"  ),
        startAfter(lastDoc),
        limit(5)
    );
  }else{
    paginationQuery = query(
        collectionRef,
        where("statusType", "==", status),
        orderBy("statusType"),
        limit(5)
    );
  }
  const querySnapshot = await getDocs(paginationQuery);
  const result: T[] = [];
  let newLastDocId
  for (const doc2 of querySnapshot.docs) {
    const item = doc2.data() as T;
    newLastDocId = doc2.id
    // @ts-ignore
    item.id = doc2.id
    result.push(item);
  }
  //@ts-ignore
  console.log(result)
  return {items:result, newLastDocId:newLastDocId};
}

export const getAllImmigrationVisas = async (status: string, lastDocId: string | null) => {
  const immigrationVisasRef = collection(firestore, IMMIGRATION_VISAS_COLLECTION);
  let airTicketsQuery
  if (lastDocId) {
    const lastDocRef = doc(firestore, IMMIGRATION_VISAS_COLLECTION, lastDocId)
    const lastDoc = await getDoc(lastDocRef)
    airTicketsQuery = query(
      immigrationVisasRef,
      where("status", "==", status),
      orderBy("id"),
      startAfter(lastDoc),
      limit(5)
    );
  } else {
    airTicketsQuery = query(
      immigrationVisasRef,
      where("status", "==", status),
      orderBy("id"),
      limit(5)
    );
  }
  const querySnapshot = await getDocs(airTicketsQuery);
  const immigrationVisas: ImmigrationVisa[] = [];
  let newLastDocId

  for (const doc2 of querySnapshot.docs) {
    const immigrationVisa = doc2.data() as ImmigrationVisa;
    newLastDocId = doc2.id
    immigrationVisas.push(immigrationVisa);
  }
  console.log(immigrationVisas);
    
  return {immigrationVisas,newLastDocId};
}

export const getAllAirTicketBookings = async () => {
  const AirTicketsRef = collection(firestore, AIR_TICKET_BOOKINGS_COLLECTION);
  const querySnapshot = await getDocs(AirTicketsRef);

  const airTickets: AirTicketBooking[] = []
  for (const doc of querySnapshot.docs) {
    const airTicketData = doc.data() as AirTicketBooking
    airTicketData.id = doc.id
    if (airTicketData.userID) {
      const user = await getUser(airTicketData.userID)
      airTicketData.user = user
    }
    airTickets.push(airTicketData)
  }
  return airTickets
}


export const updateTicketIsPaid = async (id: string, isPaid: boolean, collection: string) => {
  const airTicketRef = doc(firestore, collection, id);
  const updateData = isPaid
    ? {
      isPaid: true,
      paymentTime: Math.floor(Date.now() / 1000)
    }
    : {
      isPaid: false,
      paymentTime: deleteField()
    };

  await updateDoc(airTicketRef, updateData);
}



const getEmiratesWorkVisaQuestionnaire = async (questionnaireIDs: DocumentReference[]) => {
  const questionnaires: QuestionnaireAnswer[] = [];
  for (const questionnaireID of questionnaireIDs) {
    const questionnaireDoc = await getDoc(questionnaireID);
    if (questionnaireDoc.exists()) {
      const questionnaire = questionnaireDoc.data() as QuestionnaireAnswer;
      questionnaires.push(questionnaire);
    }
  }
  return questionnaires;
}


export const getAllEmiratesWorkVisaApplications = async () => {
  const emiratesWorkRef = collection(firestore, EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION)
  const querySnapshot = await getDocs(emiratesWorkRef)
  const emirateWorkApplications: EmiratesWorkVisaApplication[] = []
  for (const doc of querySnapshot.docs) {
    const emirateWorkApplication = doc.data() as EmiratesWorkVisaApplication
    emirateWorkApplication.id = doc.id
    if (emirateWorkApplication.userID) {
      const user = await getUser(emirateWorkApplication.userID)

      emirateWorkApplication.user = user
    }
    const questionnaires = await getEmiratesWorkVisaQuestionnaire(emirateWorkApplication.questionnaireIDs)
    emirateWorkApplication.questionnaires = questionnaires
    emirateWorkApplications.push(emirateWorkApplication)
  }

  return emirateWorkApplications
}


export const getAllSpecialOffers = async () => {
  const specialOfferRef = collection(firestore, SPECIAL_OFFERS_COLLECTION)
  const querySnapshot = await getDocs(specialOfferRef)
  const specialOffers: SpecialOffer[] = []
  for (const doc of querySnapshot.docs) {
    const data = doc.data() as SpecialOffer
    if (data.userID) {
      const user = await getUser(data.userID)
      data.user = user
    }
    specialOffers.push(data)
  }
  return specialOffers
}


export const getAllOfflineConsultations = async () => {
  const offlineConsultationsRef = collection(firestore, OFFLINE_CONSULTATIONS_COLLECTION)
  const querySnapshot = await getDocs(offlineConsultationsRef)
  const offlineConsultations: OfflineConsultation[] = []
  for (const doc of querySnapshot.docs) {
    const data = doc.data() as OfflineConsultation
    if (data.userID) {
      const user = await getUser(data.userID)
      data.user = user
    }
    offlineConsultations.push(data)
  }
  return offlineConsultations
}

export const getAllOnlineConsultations = async () => {
  const onlineConsultationsRef = collection(firestore, ONLINE_CONSULTATIONS_COLLECTION)
  const querySnapshot = await getDocs(onlineConsultationsRef)
  const onlineConsultations: OnlineConsultation[] = []
  for (const doc of querySnapshot.docs) {
    const data = doc.data() as OnlineConsultation
    if (data.userID) {
      const user = await getUser(data.userID)
      data.user = user
    }
    onlineConsultations.push(data)
  }
  return onlineConsultations
}


export const getAllInsurances = async () => {
  const insurancesRef = collection(firestore, INSURANCE_COLLECTION)
  const querySnapshot = await getDocs(insurancesRef)
  const insuranceList: Insurance[] = []
  for (const doc of querySnapshot.docs) {
    const data = doc.data() as Insurance
    data.id = doc.id
    if (data.userID) {
      const user = await getUser(data.userID)
      data.user = user
    }
    insuranceList.push(data)
  }
  return insuranceList
}

export const getAllPolandContacts = async () => {
  const contactsRef = collection(firestore, TO_CONTACT_FOR_POLAND_COLLECTION)
  const querySnapshot = await getDocs(contactsRef)
  const contactList: ContactPoland[] = []
  for (const doc of querySnapshot.docs) {
    const data = doc.data() as ContactPoland
    data.id = doc.id
    if (data.userID) {
      const user = await getUser(data.userID)
      data.user = user
    }
    contactList.push(data)
  }
  return contactList
}

export const getTotalAmount = async (collectionName: string,status:string) => {
  const data = query(collection(firestore, collectionName), where("status","==",status))
  const querySnapshot = await getDocs(data)
  return querySnapshot.size
}
export const getTotalAmount2 = async (collectionName: string,status:string) => {
  const data = query(collection(firestore, collectionName), where("statusType","==",status))
  const querySnapshot = await getDocs(data)
  return querySnapshot.size
}






