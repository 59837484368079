
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../hooks/reduxHooks";
import { EmiratesWorkVisaApplication } from "../../utils/interfaces";
import VisaInfoBlock from "./InfoBlock";
import StatusChangeButton from '../StatusChangeButton'
import { EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION, INSURANCE_COLLECTION } from '../../utils/consts'
import { updateInsuranceStatus } from "../../redux/slices/insuranceSlice";
import { Status } from "../../utils/interfaces";
import { Insurance } from "../../utils/interfaces";
import ApplicationsItemBooleanToggle from "../ApplicationsItemBooleanToggle";
export const EmiratesWorkStatuses: Status[] = [
    {
        eng: 'new',
        rus: 'Новые'
    },
    {
        eng: 'inProcess',
        rus: 'В процессе'
    },
    {
        eng: 'won',
        rus: 'Одобренные'
    },
    {
        eng: 'lost',
        rus: 'Не одобренные'
    }
];

interface InsuranceInfoProps {
    insurance: Insurance
}

const InsuranceGeneralInfoBlock: React.FC<InsuranceInfoProps> = ({ insurance }) => {
    const activeStatus: string = useAppSelector(
        (state: RootState) => state.greenCardApplications.activeStatus,
    );

    return (
        <div className="insurance-item__general">
            <h4 className="insurance-item__general__title">Общая информация</h4>
            <div className="insurance-item__general__infoContainer">
                <VisaInfoBlock title="Страна страховки" data={insurance.insuranceCountry || 'пусто'}></VisaInfoBlock>
                <div className="insurance-item__general__infoContainer__days">
                    <VisaInfoBlock title="Количество дней" data={insurance.insuranceDayCount || 'пусто'}></VisaInfoBlock>
                    <VisaInfoBlock title="Причина" data={insurance.insuranceDayCount || 'пусто'}></VisaInfoBlock>
                </div>

                <VisaInfoBlock title="Цена" data={insurance.cost || 'пусто'}></VisaInfoBlock>

                <VisaInfoBlock title="Оплачено">
                    <ApplicationsItemBooleanToggle
                        id={insurance.id}
                        isPaid={insurance.isPaid}
                        collection={INSURANCE_COLLECTION} />
                </VisaInfoBlock>


                {/* <div className="tickets-item__body__block">
                    <span className="tickets-item__body__block-key">{title}</span>
                    <h6 className="tickets-item__body__block-value">{data}</h6>
                </div> */}
                <div className="insurance-status_btn">
                    <StatusChangeButton
                        id={insurance.id}
                        isStatusType={true}
                        collection={INSURANCE_COLLECTION}
                        activeStatus={''}
                        statuses={EmiratesWorkStatuses}
                        bottomOffset={8}
                        topOffset={3}
                        dispatchMethod={updateInsuranceStatus}
                    />
                </div>

            </div>

        </div>
    );
}
export default InsuranceGeneralInfoBlock;