import React from "react"
import { AirTicketApplicant } from "../../utils/interfaces"
import TicketInfoBlock from "./infoBlock"
import { getGender } from "../../utils/utils"
import ApplicantImage from "../GCApplicationsItem/ApplicantImage"


interface TicketApplicantsProps {
    applicants: AirTicketApplicant[]
}

const TicketApplicants: React.FC<TicketApplicantsProps> = (
    {applicants}
) => {
    
    

    return (
        <div className="tickets-item__section">
        <h4 className="tickets-item__title">Данные заявителя</h4>
        {
          applicants.map((applicant) => (
          <div className="tickets-item__grid">
            <div className="tickets-item__column">
              <TicketInfoBlock title="Фото" children={<ApplicantImage photoLink={applicant.photoLink}></ApplicantImage>} />
              <TicketInfoBlock title="Полное имя" data={`${applicant.name} ${applicant.surname}`}/>
              <TicketInfoBlock title="Дата рождения" data={applicant.dateOfBirth}/>
            </div>
            <div className="tickets-item__column">
              <TicketInfoBlock title="Пол" data={getGender(applicant.gender)}/>
              <TicketInfoBlock title="ИИН" data={applicant.iin}/>
              <TicketInfoBlock title="Гражданство" data={applicant.citizenship} />
              <TicketInfoBlock title="Номер паспорта" data={applicant.passportID} />
              <div className="tickets-item__grid">
                <div className="tickets-item__column">
                  <TicketInfoBlock title="Дата выдачи" data={applicant.passportGivenDate} />
                </div>
                <div className="tickets-item__column">
                  <TicketInfoBlock title="Действителен до" data={applicant.passportDueToDate} />
                </div>
              </div>
              <TicketInfoBlock title="Орган, который выдал" data={applicant.authority} />
            </div>
          </div>
          ))
        }
        
      </div>
    )
}

export default TicketApplicants