import ApplicationsPage from '../pages/ApplicationsPage';
import ChatPage from '../pages/ChatPage';
import FeedbackPage from '../pages/FeedbackPage';
import GreenCardsPage from '../pages/GreenCardsPage';
import ImmigrationVisasPage from '../pages/ImmigrationVisasPage';
import LoginPage from '../pages/LoginPage';
import ProfilesPage from '../pages/ProfilesPage';
import AirTicketBookingsPage from '../pages/AirTicketBookingsPage';
import {
  TOURIST_VISAS_ROUTE,
  CHAT_ROUTE,
  FEEDBACK_ROUTE,
  GREEN_CARDS_ROUTE,
  LOGIN_ROUTE,
  USERS_ROUTE,
  IMMIGRATION_VISAS_ROUTE,
  AIR_TICKET_BOOKINGS_ROUTE,
  EMIRATES_WORK_VISA_APPLICATIONS,
  SPECIAL_OFFERS,
  OFFLINE_CONSULTATIONS_ROUTE,
  INSURANCE_ROUTE,
  ONLINE_CONSULTATIONS_ROUTE,
  TO_CONTACT_FOR_POLAND_ROUTE
} from './consts';
import EmiratesWorkVisaPage from '../pages/EmiratesWorkVisaPage';
import SpecialOffersPage from '../pages/SpecialOffersPage';
import OfflineConsultaionsPage from '../pages/OfflineConsultationsPage';
import InsurancePage from '../pages/InsurancePage';
import OnlineConsultationsPage from '../pages/OnlineConsultationsPage';
import PolandContactsPage from '../pages/ContactForPolandPage';



export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    element: LoginPage
  }
];

export const privateRoutes = [
  {
    path: TOURIST_VISAS_ROUTE,
    element: ApplicationsPage
  },
  {
    path: CHAT_ROUTE,
    element: ChatPage
  },
  {
    path: FEEDBACK_ROUTE,
    element: FeedbackPage
  },
  {
    path: USERS_ROUTE,
    element: ProfilesPage
  },
  {
    path: GREEN_CARDS_ROUTE,
    element: GreenCardsPage
  },
  {
    path: IMMIGRATION_VISAS_ROUTE,
    element: ImmigrationVisasPage
  },
  {
    path: AIR_TICKET_BOOKINGS_ROUTE,
    element: AirTicketBookingsPage
  },
  {
    path:EMIRATES_WORK_VISA_APPLICATIONS,
    element:EmiratesWorkVisaPage
  },
  {
    path:SPECIAL_OFFERS,
    element:SpecialOffersPage
  },
  {
    path:OFFLINE_CONSULTATIONS_ROUTE,
    element:OfflineConsultaionsPage
  },
  {
    path:ONLINE_CONSULTATIONS_ROUTE,
    element:OnlineConsultationsPage
  },
  {
    path:INSURANCE_ROUTE,
    element:InsurancePage
  },
  {
    path:TO_CONTACT_FOR_POLAND_ROUTE,
    element:PolandContactsPage
  }
  
];
