import { ImmigrationVisa, Profile } from "../../../../utils/interfaces";
import ImmigrationVisasInfoBlock from "./InfoBlock";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../hooks/reduxHooks";
import { getUser } from "../../../../firebase/firestore";
import { setVisaProfile } from "../../../../redux/slices/immigrationVisaSlice";
import Loader from "../../../../components/Loader";
interface ImmigrationVisasProfileSectionProps {
  visa: ImmigrationVisa | null | undefined;
}

const ImmigrationVisasProfileSection: React.FC<ImmigrationVisasProfileSectionProps> = ({ visa }) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const fetchProfileData = async () => {
    setIsLoading(true);
    const profileData = await getUser(visa?.userID||'')
    dispatch(setVisaProfile({id:visa?.id,user:profileData}))
    setIsLoading(false);
  }

  useEffect(() => {
    fetchProfileData();
  }, [dispatch]);
  return (
    isLoading ? <Loader></Loader> :
    visa ? <div className="iv-item__grid">
      <ImmigrationVisasInfoBlock title="Имя пользователя" value={visa.user?.username  || ""} />
      <ImmigrationVisasInfoBlock title="Адрес" value={visa.user?.address || ""} />
      <ImmigrationVisasInfoBlock title="E-mail" value={visa.user?.email || ""} />
      <ImmigrationVisasInfoBlock title="Квартира" value={visa.user?.apartment || ""} />
      <ImmigrationVisasInfoBlock title="Телефон" value={visa.user?.phoneNumber || ""} />
    </div> : <div className="iv-item__grid">
      <ImmigrationVisasInfoBlock title="Пользователь не найден" value="Заявку оставил пользователь, который не заполнил свой профиль" />
    </div>
  );
}

export default ImmigrationVisasProfileSection;