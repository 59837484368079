import { createSlice } from '@reduxjs/toolkit';
import { ImmigrationVisa } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface ImmigrationVisasState {
  immigrationVisasList: ImmigrationVisa[];
  activeStatus: string;
  productsAmount: number | 0,
  currentPage: number,
  lastDocId:string|null,
  totalAmount:number,
}

const initialState: ImmigrationVisasState = {
  immigrationVisasList: [],
  activeStatus: StatusTypes.NEW,
  productsAmount:  0,
  currentPage: 1,
  lastDocId:null,
  totalAmount:0
};

export const immigrationVisasSlice = createSlice({
  name: 'immigrationVisas',
  initialState,
  reducers: {
    addImmigrationVisas(state, action) {
      state.immigrationVisasList = action.payload;
    },
    changeImmigrationVisasActiveStatus(state, action) {
      state.activeStatus = action.payload;
    },
    updateImmigrationVisaStatus(state, action) {
      const { id, status } = action.payload;
      const immigrationVisa = state.immigrationVisasList.find(item => item.id === id);
      if (immigrationVisa) {
        immigrationVisa.status = status;
      }
    },
    updateLastDoc(state,action){
      state.lastDocId = action.payload
    },
    updatePageNumber(state,action){
      state.currentPage = action.payload
    },
    setVisaProfile(state,action){
      const {id,user} = action.payload
      const ind = state.immigrationVisasList.findIndex((item)=>item.id===id)
      if(ind!==-1){
        state.immigrationVisasList[ind].user = user
      }
    }
  },
});

export const { addImmigrationVisas, changeImmigrationVisasActiveStatus, setVisaProfile,updateImmigrationVisaStatus,updateLastDoc,updatePageNumber } = immigrationVisasSlice.actions;
export default immigrationVisasSlice.reducer;
