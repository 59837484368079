import { createSlice } from '@reduxjs/toolkit';
import { EmiratesWorkVisaApplication } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface EmiratesWorkVisaApplicationState {
    applicationList: EmiratesWorkVisaApplication[];
    searchText: string;
    activeStatus: string;
    currentPage:number;
    lastDocId:string|null;
}

const initialState: EmiratesWorkVisaApplicationState = {
    applicationList: [],
    searchText: '',
    activeStatus: StatusTypes.NEW,
    currentPage:1,
    lastDocId:null
};

export const emiratesWorkVisaSlice = createSlice({
    name: 'emiratesWorkVisaApplications',
    initialState,
    reducers: {
        updateEmirateWorkVisaStatusType(state, action) {
            const { id, statusType } = action.payload
            const application = state.applicationList.find(item => item.id === id)
            if (application) {
                application.statusType = statusType
            }
        },
        updateEmiratesApplicationStatus(state, action) {
            const { id, status } = action.payload;
            const applicationItem = state.applicationList.find(item => item.id === id);
            if (applicationItem) {
                applicationItem.status = status;
            }
        },
        addEmiratesWorkVisas(state, action) {
            state.applicationList = action.payload
        },
        changeWorkVisaApplicationStatus(state, action) {
            state.activeStatus = action.payload
        },
        changeApplicationsSearchText(state, action) {
            state.searchText = action.payload
        },
        updateLastDoc(state,action){
            state.lastDocId = action.payload
        },
        updatePageNumber(state,action){
            state.currentPage = action.payload
        },
        setEWApplicationProfile(state,action){
            const {id,user} = action.payload
            const ind = state.applicationList.findIndex((item)=>item.id===id)
            if(ind!==-1){
                state.applicationList[ind].user = user
            }
        },
        setEWApplicationQuestionnaire(state,action){
            const {id, questionnaires} = action.payload
            console.log(action.payload)
            const ind = state.applicationList.findIndex((item)=>item.id===id)
            if(ind!==-1){
                state.applicationList[ind].questionnaires = questionnaires
            }
        }
    },
});

export const {
    updateEmirateWorkVisaStatusType,
    changeApplicationsSearchText,
    updateEmiratesApplicationStatus,
    addEmiratesWorkVisas,
    changeWorkVisaApplicationStatus,
    updateLastDoc,
    updatePageNumber,
    setEWApplicationQuestionnaire,
    setEWApplicationProfile
} = emiratesWorkVisaSlice.actions;
export default emiratesWorkVisaSlice.reducer;
