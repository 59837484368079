import { SpecialOfferApplicant } from "../../utils/interfaces";
import { getGender } from "../../utils/utils";
import SpecialOfferInfoBlock from "./SpecialOfferInfoBlock";


interface SpecialOfferApplicantsProps {
    applicants: SpecialOfferApplicant[]
}


const SpecialOfferApplicants: React.FC<SpecialOfferApplicantsProps> = ({
    applicants
}) => {
    const amountOfApplicants = applicants.length;
    const sectionTitle = `Данные ${amountOfApplicants > 1 ? "заявителей" : "заявителя"
        }`;

    return (
        <div className="offers-item__applicants">
            <h6 className="offers-item__applicants__title">{sectionTitle}</h6>
            {applicants.map((applicant, index) => (
                <>
                    <div className="offers-item__applicants__grid" key={index}>
                        <div className="offers-item__applicants__grid__column">
                            <SpecialOfferInfoBlock
                                title="Полное имя"
                                data={`${applicant.name} ${applicant.surname}`}
                            />
                            <SpecialOfferInfoBlock
                                title="Дата рождения"
                                data={applicant.dateOfBirth}
                            />
                            <SpecialOfferInfoBlock
                                title="Гражданство"
                                data={applicant.citizenship}
                            />
                            <SpecialOfferInfoBlock title="Пол" data={getGender(applicant.gender)} />
                        </div>
                        <div className="offers-item__applicants__grid__column">
                            <SpecialOfferInfoBlock title="ИИН" data={applicant.IIN} />
                            <SpecialOfferInfoBlock
                                title="Номер паспорта"
                                data={applicant.passportID}
                            />
                            <SpecialOfferInfoBlock
                                title="Дата выдачи"
                                data={applicant.passportGivenDate}
                            />
                            <SpecialOfferInfoBlock
                                title="Действителен до"
                                data={applicant.passportDueToDate}
                            />

                            <SpecialOfferInfoBlock
                                title="Орган, который выдал"
                                data={applicant.authority}
                            />
                        </div>
                    </div>
                    {index + 1 !== amountOfApplicants && (
                        <div className="applications-item__line"></div>
                    )}
                </>
            ))}
        </div>
    );
};

export default SpecialOfferApplicants;
