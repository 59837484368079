import { createSlice } from '@reduxjs/toolkit';
import { AirTicketBooking } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface AirTicketsState {
    ticketsList: AirTicketBooking[];
    activeStatus:string;
    searchText: string;
    currentPage:number;
    lastDocId:string|null
}

const initialState: AirTicketsState = {
    ticketsList: [],
    searchText: '',
    activeStatus:'new',
    currentPage:1,
    lastDocId:null,
};

export const airTicketsSlice = createSlice({
    name: 'airTickets',
    initialState,
    reducers: {
        updateTicketStatus(state, action){
            const { id, statusType } = action.payload
            const application = state.ticketsList.find(item => item.id === id)
            if (application) {
                application.statusType = statusType
            }
        },
        addAirTicketBookings(state,action){
          state.ticketsList = action.payload    
        },
        changePaymentStatus(state, action) {
            const { orderID, status } = action.payload
            const airTicket = state.ticketsList.find(item => item.orderID === orderID)
            if (airTicket) {
                airTicket.isPaid = status
            }
        },
        changeStatus(state,action){
          state.activeStatus = action.payload
        },
        updateLastDoc(state,action){
            state.lastDocId = action.payload
        },
        updatePageNumber(state,action){
            state.currentPage = action.payload
        },
        changeSearchText(state,action){
            state.searchText =action.payload
        }
    },
});

export const {
    changeSearchText,
    changePaymentStatus,
    addAirTicketBookings ,
    changeStatus,
    updateTicketStatus,
    updatePageNumber,
    updateLastDoc
} = airTicketsSlice.actions;
export default airTicketsSlice.reducer;
