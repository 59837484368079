import { OfflineConsultation } from '../../utils/interfaces';
import NotFound from "../NotFound";
import OfflineConsultationComponent from "./OfflineConsultationItem";

interface OfflineConsultationListProps {
    consultations: OfflineConsultation[];
}

const OfflineConsultationList: React.FC<OfflineConsultationListProps> = ({
    consultations,
}) => {
    console.log();

    return (
        consultations.length > 0 ? <div className="workvisas-grid">
            {consultations.map((item, index) => (
                <OfflineConsultationComponent consultation={item} key={index}></OfflineConsultationComponent>
            ))}
        </div> : <NotFound />
    );
};

export default OfflineConsultationList;