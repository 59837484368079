
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../hooks/reduxHooks";
import { EmiratesWorkVisaApplication } from "../../utils/interfaces";
import VisaInfoBlock from "./EWInfoBlock";
import StatusChangeButton from '../StatusChangeButton'
import { EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION } from '../../utils/consts'
import { updateEmirateWorkVisaStatusType } from '../../redux/slices/emiratesWorkVisaSlice'
import { Status } from "../../utils/interfaces";


export const EmiratesWorkStatuses: Status[] = [
    {
        eng: 'new',
        rus: 'Новые'
    },
    {
        eng: 'inProcess',
        rus: 'В процессе'
    },
    {
        eng: 'won',
        rus: 'Одобренные'
    },
    {
        eng: 'lost',
        rus: 'Не одобренные'
    }
];

interface EWorkingVisaProps {
    application: EmiratesWorkVisaApplication
}

const EWorkingVisaGeneralInfo: React.FC<EWorkingVisaProps> = ({ application }) => {
    const activeStatus: string = useAppSelector(
        (state: RootState) => state.greenCardApplications.activeStatus,
    );

    return (
        <div className="workvisas-item__general">
            <h4 className="workvisas-item__general__title">Общая информация</h4>
            <div className="workvisas-item__general__infoContainer">
                <VisaInfoBlock title="Гражданство" data={application.citizenship || 'пусто'}></VisaInfoBlock>
                <VisaInfoBlock title="Пункт назначения" data={application.country || 'пусто'}></VisaInfoBlock>
                {/* <TicketInfoBlock title="Оплачено">
                    <ApplicationsItemBooleanToggle
                        id={application.id}
                        isPaid={application.status}
                        collection={AIR_TICKET_BOOKINGS_COLLECTION} />
                </TicketInfoBlock> */}


                {/* <div className="tickets-item__body__block">
                    <span className="tickets-item__body__block-key">{title}</span>
                    <h6 className="tickets-item__body__block-value">{data}</h6>
                </div> */}
                <div className="workvisas-status_btn">
                    <StatusChangeButton
                        id={application.id}
                        isStatusType={false}
                        collection={EMIRATES_WORK_VISA_APPLICATIONS_COLLECTION}
                        activeStatus={''}
                        statuses={EmiratesWorkStatuses}
                        bottomOffset={8}
                        topOffset={3}
                        dispatchMethod={updateEmirateWorkVisaStatusType}
                    />
                </div>

            </div>

        </div>
    );
}
export default EWorkingVisaGeneralInfo;