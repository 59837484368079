import { EmiratesWorkVisaApplication } from "../utils/interfaces";
import NotFound from "./NotFound";
import EmiratesWorkVisaApplicationItem from "./EWApplicationsItem";

interface EmiratesWorkWisaListProps {
  applications: EmiratesWorkVisaApplication[];
}

const EmiratesWorkVisaList: React.FC<EmiratesWorkWisaListProps> = ({
  applications,
}) => {
  
  return (
    applications.length > 0 ? <div className="workvisas-grid">
      {applications.map((item) => (
        <EmiratesWorkVisaApplicationItem key={item.id} application={item}></EmiratesWorkVisaApplicationItem>
      ))} 
    </div> : <NotFound/>
  );
};

export default EmiratesWorkVisaList;