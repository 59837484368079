import ApplicationsItemBlock from "./ApplicationsItemBlock";
import {Application, QuestionnaireAnswer} from "../../utils/interfaces";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {RootState} from "../../redux/store";
import {getQuestionnaires} from "../../firebase/firestore";
import {setApplicationProfile, setApplicationQuestionnaire} from "../../redux/slices/applicationsSlice";
import Loader from "../Loader";

interface ApplicationsItemQuestionnaireProps {

  application: Application;
}

const ApplicationsItemQuestionnaire: React.FC<
  ApplicationsItemQuestionnaireProps
> = ({ application }) => {
  const dispatch = useAppDispatch()
  const [isLoading,setIsLoading] = useState(false)
  const sectionTitle =
    application.questionnaireIDs && application.questionnaireIDs.length > 1 ? "Анкеты" : "Анкета";
  useEffect(() => {
    const getQuestionnairesData = async () =>{
      setIsLoading(true)
      const result = await getQuestionnaires(application.questionnaireIDs || [])

      dispatch(setApplicationQuestionnaire({id:application.id, questionnaires:result}))
      setIsLoading(false)

    }
    getQuestionnairesData()
  }, [dispatch]);
  return (
    <div className="applications-item__section">
      <h6 className="applications-item__title">{sectionTitle}</h6>
      {isLoading ?
          <Loader></Loader>
          :
          application.questionnaires && application.questionnaires.length > 0 ?
                application.questionnaires.map((questionnaire, index) => (
                    <>
                      <div
                          className="applications-item__grid applications-item__questionnaire"
                          key={questionnaire.id}
                      >
                        {questionnaire.applicantQuestionnaireAnswers.map(
                            (item, index) => (
                                <ApplicationsItemBlock
                                    title={item.question}
                                    value={item.answer}
                                    key={index}
                                />
                            ),
                        )}
                      </div>
                      {index + 1 !== application.questionnaires.length && (
                          <div className="applications-item__line"></div>
                      )}
                    </>
                ))
              : <h5>
            Анкета не была заполнена
              </h5>
      }
    </div>
  );
};

export default ApplicationsItemQuestionnaire;
