import StatusChangeButton from "../StatusChangeButton";
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../hooks/reduxHooks";
import {AIR_TICKET_BOOKINGS_COLLECTION, GeneralStatuses} from "../../utils/consts";
import ApplicationsItemBooleanToggle from "../ApplicationsItemBooleanToggle";
import { AirTicketBooking } from "../../utils/interfaces";

import { updateTicketStatus } from "../../redux/slices/airTicketsSlice";
import TicketInfoBlock from "./infoBlock";

interface TicketGeneralInfoProps {
    ticket: AirTicketBooking
}

const TicketGeneralInfoBlock: React.FC<TicketGeneralInfoProps> = ({ ticket }) => {
    const activeStatus: string = useAppSelector(
        (state: RootState) => state.greenCardApplications.activeStatus,
    );

    return (
        <div className="tickets-item__body__block">
            <h4 className="tickets-item__body__header">Общая информация</h4>
            <div className="tickets-item__body__block">
                <TicketInfoBlock title="Пункт назначения" data={ticket.bookingToCountry}></TicketInfoBlock>
                <div className="tickets-item__body__block-date">
                    <TicketInfoBlock title="Начальная дата" data={ticket.fromDate}></TicketInfoBlock>
                    {'-'}
                    <TicketInfoBlock title="Конечная дата" data={ticket.toDate}></TicketInfoBlock>
                </div>
                <TicketInfoBlock title="Конечная цена" data={ticket.cost}></TicketInfoBlock>
                <TicketInfoBlock title="Оплачено">
                    <ApplicationsItemBooleanToggle
                        id={ticket.id}
                        isPaid={ticket.isPaid}
                        collection={AIR_TICKET_BOOKINGS_COLLECTION} />
                </TicketInfoBlock>
                <div className="tickets-item__body__block__status_btn">
                    <StatusChangeButton
                        id={ticket.id}
                        isStatusType={true}
                        collection={AIR_TICKET_BOOKINGS_COLLECTION}
                        activeStatus={''}
                        statuses={GeneralStatuses}
                        bottomOffset={8}
                        topOffset={3}
                        dispatchMethod={updateTicketStatus}
                    />
                </div>

                {/* <div className="tickets-item__body__block">
                    <span className="tickets-item__body__block-key">{title}</span>
                    <h6 className="tickets-item__body__block-value">{data}</h6>
                </div> */}

            </div>
        </div>
    );
}
export default TicketGeneralInfoBlock;