import GreenCardApplicationsInfoBlock from "./InfoBlock";
import { GreenCardApplicationsItemProps } from "./index";
import {useEffect, useState} from "react";
import {getQuestionnaires} from "../../firebase/firestore";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {setGreenCardQuestionnaire} from "../../redux/slices/greenCardApplicationsSlice";
import Loader from "../Loader";

const GreenCardApplicationsItemQuestionnaireBlock: React.FC<GreenCardApplicationsItemProps> = ({ greenCardApplication }) => {
    const dispatch = useAppDispatch()
    const [isLoading,setIsLoading] = useState(false)
    useEffect(() => {
        const getQuestionnairesData = async () =>{
            setIsLoading(true)
            const result = await getQuestionnaires(greenCardApplication.questionnaireIDs || [])
            dispatch(setGreenCardQuestionnaire({id:greenCardApplication.id, questionnaires:result}))
            setIsLoading(false)
        }
        getQuestionnairesData()
    }, [dispatch]);
    console.log(greenCardApplication)

  return (
    <div className="gca-item__section">
      <h4 className="gca-item__title">Анкета</h4>
      {
          isLoading ? <div className='spinner-container'><Loader></Loader></div>
              :
              greenCardApplication.questionnaires?.map((questionnaire) => (
                  <div className="gca-item__questionnaire">{
                    questionnaire.applicantQuestionnaireAnswers.map((item) => (
                    <GreenCardApplicationsInfoBlock title={item.question} value={item.answer}/>
                    ))
                  }</div>
        ))
      }
    </div>
  );  
}
export default GreenCardApplicationsItemQuestionnaireBlock;