import { createSlice } from '@reduxjs/toolkit';
import { SpecialOffer } from '../../utils/interfaces';
import { StatusTypes } from '../../utils/enums';

interface SpecialOffersState {
    specialOffers: SpecialOffer[];
    searchText: string;
    activeStatus: string;
    currentPage:number;
    lastDocId:string|null
}

const initialState: SpecialOffersState = {
    specialOffers: [],
    searchText: '',
    activeStatus: StatusTypes.NEW,
    lastDocId:null,
    currentPage:1
};

export const specialOffersSlice = createSlice({
    name: 'airTickets',
    initialState,
    reducers: {
        updateTicketStatus(state, action) {
            const { orderID } = action.payload
            const airTicket = state.specialOffers.find(item => item.orderID === orderID)
        },
        addSpecialOffers(state, action) {
            console.log(action.payload)
            state.specialOffers = action.payload
        },
        changeSpecialOffersStatus(state, action) {
            state.activeStatus = action.payload
        },
        changePaymentStatus(state, action) {
            const { orderID, status } = action.payload
            const airTicket = state.specialOffers.find(item => item.orderID === orderID)
            if (airTicket) {
                airTicket.isPaid = status
            }
        },
        changeSearchText(state, action) {
            state.searchText = action.payload
        },
        updateLastDoc(state,action){
            state.lastDocId = action.payload
        },
        updatePageNumber(state,action){
            state.currentPage = action.payload
        },
        setSpecialOfferProfile(state,action){
            const {id,user} = action.payload
            const ind = state.specialOffers.findIndex((item)=>item.id===id)
            if(ind!==-1){
                state.specialOffers[ind].user = user
            }
        },
        setEWApplicationQuestionnaire(state,action){
            const {id, questionnaires} = action.payload
            console.log(action.payload)
            const ind = state.specialOffers.findIndex((item)=>item.id===id)
            if(ind!==-1){
                // state.specialOffers[ind].questionnaires = questionnaires
            }
        }
    },
});

export const { changePaymentStatus, addSpecialOffers, updateTicketStatus, changeSpecialOffersStatus,changeSearchText, updatePageNumber,updateLastDoc,setEWApplicationQuestionnaire,setSpecialOfferProfile } = specialOffersSlice.actions;
export default specialOffersSlice.reducer;
