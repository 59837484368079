import React from "react";
import { Profile } from "../../utils/interfaces";
import ApplicantImage from "../GCApplicationsItem/ApplicantImage";
import VisaInfoBlock from "./InfoBlock";
import Link from "../Link";
import { InsuranceApplicants } from "../../utils/interfaces";
import { getGender } from "../../utils/utils";

interface SpecialOfferApplicantsProps {
    applicants: InsuranceApplicants[]
}


const InsuranceApplicantsComponent: React.FC<SpecialOfferApplicantsProps> = ({
    applicants
}) => {
    const amountOfApplicants = applicants.length;
    const sectionTitle = `Данные ${amountOfApplicants > 1 ? "заявителей" : "заявителя"
        }`;

    return (
        <div className="offers-item__applicants">
            <h6 className="offers-item__applicants__title">{sectionTitle}</h6>
            {applicants.map((applicant, index) => (
                <>
                    <div className="offers-item__applicants__grid" key={index}>
                        <div className="offers-item__applicants__grid__column">
                            <VisaInfoBlock
                                title="Полное имя"
                                data={`${applicant.name} ${applicant.surname}`}
                            />
                            <VisaInfoBlock
                                title="Дата рождения"
                                data={applicant.dateOfBirth}
                            />
                            <VisaInfoBlock
                                title="Гражданство"
                                data={applicant.citizenship}
                            />
                            <VisaInfoBlock title="Пол" data={getGender(applicant.gender)} />
                        </div>
                        <div className="offers-item__applicants__grid__column">
                            <VisaInfoBlock title="ИИН" data={applicant.IIN} />
                            <VisaInfoBlock
                                title="Номер паспорта"
                                data={applicant.passportID}
                            />
                            <div className="offers-item__applicants__grid__child">
                                <div className="offers-item__applicants__grid__child-item">
                                    <VisaInfoBlock
                                        title="Дата выдачи"
                                        data={applicant.passportGivenDate}
                                    />
                                </div>
                                <div className="offers-item__applicants__grid__child-item">
                                    <VisaInfoBlock
                                        title="Действителен до"
                                        data={applicant.passportDueToDate}
                                    />
                                </div>

                            </div>
                            <VisaInfoBlock
                                title="Орган, который выдал"
                                data={applicant.authority}
                            />
                        </div>
                    </div>
                    {index + 1 !== amountOfApplicants && (
                        <div className="applications-item__line"></div>
                    )}
                </>
            ))}
        </div>
    );
};

export default InsuranceApplicantsComponent;
