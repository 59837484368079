import { SpecialOffer } from "../utils/interfaces";
import NotFound from "./NotFound";
import SpecialOfferItem from "./SpecialOfferItem";
import { Insurance } from "../utils/interfaces";
import InsuranceItem from "./InsuranceItem";

interface InsuranceListProps {
  insuranceList: Insurance[];
}

const InsuranceList: React.FC<InsuranceListProps> = ({
  insuranceList,
}) => {
  
  return (
    insuranceList.length > 0 ? <div className="workvisas-grid">
      {insuranceList.map((item,index) => (

        <InsuranceItem insurance={item}></InsuranceItem>
        // <SpecialOfferItem key={index} specialOffer={item}></SpecialOfferItem>
        // <EmiratesWorkVisaApplicationItem key={item.id} application={item}></EmiratesWorkVisaApplicationItem>
      ))} 
    </div> : <NotFound/>
  );
};

export default InsuranceList;