import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { RootState } from '../redux/store';
import {OfflineConsultation, OnlineConsultation} from '../utils/interfaces';
import {getTotalAmount2, paginationFunction} from '../firebase/firestore';
import SectionHeader from '../components/SectionHeader';
import {GeneralStatuses, ONLINE_CONSULTATIONS_COLLECTION} from '../utils/consts';
import Loader from '../components/Loader';
import OnlineConsultationList from '../components/Consultations/OnlineConsultationList';
import {
    addOnlineConsultations,
    changeOnlineConsultationStatus,
    changeConsultationSearchText,
    updateLastDoc,
    updatePageNumber
} from "../redux/slices/onlineConsultationSlice";
import Pagination from "../components/Pagination";

const OnlineConsultationsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onlineConsultationsList: OnlineConsultation[] = useAppSelector((state: RootState) => state.onlineConsultations.consultations)
    const searchText: string = useAppSelector((state: RootState) => state.onlineConsultations.searchText)
    const currentPage = useAppSelector((state:RootState)=>state.onlineConsultations.currentPage)
    const lastDocId = useAppSelector((state:RootState)=>state.onlineConsultations.lastDocId)
    const activeStatus = useAppSelector((state:RootState)=>state.onlineConsultations.activeStatus)
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        const fetchConsultations = async () => {
            setIsLoading(true);
            const amount = await getTotalAmount2(ONLINE_CONSULTATIONS_COLLECTION,activeStatus)
            setTotalAmount(amount)
            const {items ,newLastDocId } = await paginationFunction<OfflineConsultation>(ONLINE_CONSULTATIONS_COLLECTION,lastDocId,activeStatus)
            dispatch(updateLastDoc(newLastDocId))
            dispatch(addOnlineConsultations(items));
            setIsLoading(false);
        };
        fetchConsultations();
    }, [activeStatus,currentPage]);

    const changeActiveStatus = (value: string) => {
        dispatch(updatePageNumber(1))
        dispatch(updateLastDoc(null))
        dispatch(changeOnlineConsultationStatus(value))
    }


    const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeConsultationSearchText(e.target.value));
    };

    console.log(activeStatus)
    return (
        <div className="content">
            <div className="workvisas">
                <h2 className="workvisas__title">Список онлайн консультаций</h2>
                <SectionHeader
                    searchIsNumeric={false}
                    searchPlaceholder="Поиск по имени..."
                    activeStatus={activeStatus}
                    numberOfNewItems={
                        activeStatus === 'new' ? totalAmount : 0
                    }
                    statuses={GeneralStatuses}
                    setActiveStatus={changeActiveStatus}
                    handleChangeSearchText={handleChangeSearchText}
                    hasSearch={true}
                />
                <div className="tickets-wrapper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <OnlineConsultationList consultations={onlineConsultationsList}></OnlineConsultationList>
                    )}
                </div>
                <Pagination
                    onChangePage={(item) => dispatch(updatePageNumber(item))}
                    currentPage={currentPage}
                    itemsPerPage={5}
                    collectionName={ONLINE_CONSULTATIONS_COLLECTION}
                    currentStatus={activeStatus}
                    totalItems={totalAmount}
                ></Pagination>
            </div>
        </div>
    )
}

export default OnlineConsultationsPage;